import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user-slice/user-slice';
import contentReducer from './content-slice/content-slice';
import categoryReducer from './category-slice/category-slice';
import subscriptionPlanReducer from './subscription-plan-slice/subscription-plan-slice';
import consultantSlice from './consultant-slice/consultant-slice';
import blogSlice from './blog-slice/blog-slice';
import consultationSlice from './consultation-slice/consultation-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    content: contentReducer,
    category: categoryReducer,
    subscriptionPlans: subscriptionPlanReducer,
    consultant: consultantSlice,
    blog: blogSlice,
    consultation:consultationSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
