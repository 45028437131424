import {FC} from 'react';
import { Outlet } from 'react-router-dom';

const MobileLayout: FC<{ headerClass?: string }> = ({ headerClass = '' }) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default MobileLayout;
