export enum ROUTE_PATHS {
  LOGIN = '/:lang/login',
  HOME = '/',
  HOME2 = '/:lang/',
  SIGNUP = '/:lang/signup',
  CATEGORY = '/:lang/category/:id/:name',
  VERIFY_OTP = '/:lang/verify-otp',
  CONSULTANT = '/:lang/consultant/:id/:name?',
  CHAT = '/:lang/chat',
  NOTIFICATION = '/:lang/notification',
  USER_PROFILE = '/:lang/profile',
  REGISTER_CONSULTANT = '/:lang/register-consultant',
  FAQ_PAGE = '/:lang/faq',
  PRIVACY_POLICY = '/:lang/privacy-policy',
  TERMS_AND_CONDITIONS = '/:lang/terms-and-conditions',
  MOBILE_MARKETING = '/:lang/mobile-marketing',
  PAGE_NOT_FOUND = '*',
  PAYMENT_SUCCESS = '/:lang/payment-success',
  LOGOUT = '/:lang/logout',
  VERIFY_EMAIL = '/:lang/verify-email',
  UPDATE_PASSWORD = '/:lang/password-reset',
  MOBILECHAT= '/:lang/mobile-chat',
  TWITTER_CALLBACK= '/:lang/Login/twitter/callback',
  API_PATH="/:lang/api6/check-version",
  ABOUT="/:lang/about",
  CONSULTATION_REQUEST = '/:lang/consultant/:id/consultation-request/:consultationType',
  CONSULTATION_REQUEST_SUCCESS = '/:lang/consultation-request/:id',
  MANAGE_SCHEDULE = '/:lang/manage-schedule',
  APP_STORE = '/fasrly-app',
  ACCOUNT_DELETE = ':lang/account-delete'
}
