import { createAsyncThunk } from '@reduxjs/toolkit';
import { iCategory, iThunkApi } from '../../types/commonInterfaces';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import { REACT_APP_BASE_API_URL_V2 } from 'contants/envConstants';

export const getCategoryListThunk = createAsyncThunk<
  iCategory[],
  undefined,
  iThunkApi
>('category/getCategoryList', async (arg, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CATEGORIES,
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return resp.data;
});
