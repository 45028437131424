import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Link, Outlet, useParams } from 'react-router-dom';

import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { iUpadteNamePayload } from 'types/userInterfaces';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { ROUTE_PATHS } from 'contants/routePaths';
import { changUserLocaleOnServerThunk, getUserDetailsThunk } from 'store/user-slice/user-thunks';
import { setLangInLS } from 'utils/storageUtils';
import { NAV_LINK_TYPES, SUPPORTED_LANGS } from 'contants/commonConstants';
import { changeContentLang } from 'store/content-slice/content-slice';
import { getCategoryListThunk } from 'store/category-slice/category-thunks';
import { getNavBarContentThunk } from 'store/content-slice/content-thunks';
import { OtherBrokenLinks, colors, excludeLoginButtonRoutes, images, navLinks } from 'utils/Data';
import { callingOptions, iIncomingCallConsultant } from 'types/commonInterfaces';
import { messaging, requestPermission } from '../../../firebase';
import UserMenuLinks from './UserMenuLinks';
import NavButton from 'layout/Button/NavButton';
import ReactLink from 'layout/others/ReactLink';
import LogoWhite from 'assets/images/svg/LogoWhite';
import LangSvg from 'assets/images/svg/LangSvg';
import ConsultantCallModal from 'components/modals/ConsultantCallModal';
import { setIncomingCallConsultationId, setIsConsultantInMeeting, setSelectedCall } from 'store/consultation-slice/consultation-slice';
import { onMessage } from 'firebase/messaging';
import './NavBar.scss';
import { checkIfLangExist } from 'utils/commonUtils';

const linkRoutes = [ROUTE_PATHS.HOME, '', 'https://blog.fasrly.com/', `/register-consultant`];

const NavBar: FC<{ headerClass?: string; headerBorder?: boolean }> = ({ headerClass = '', headerBorder }) => {
  let navigate = useNavigate();
  const { lang } = useParams();
  const [show, setShow] = useState(false);
  const [callModal, setShowCallModal] = useState(false);
  const [incomingCallInfo, setIncomingCallInfo] = useState<iIncomingCallConsultant>();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.userDetails);
  const { userLang, navBarContent } = useAppSelector((state) => state.content);
  const authToken = useAppSelector((state) => state.user.authToken);
  const categoryList = useAppSelector((state) => state.category.categoryList);
  const dispatch = useAppDispatch();
  const { isConsultantInsideMeeting } = useAppSelector((state) => state.consultation);
  let endRoute = location.pathname.substring(location.pathname.lastIndexOf('/')).slice(1);

  let bgImgCondition =
    window.location.pathname === '/' || window.location.pathname === `/${userLang}` || window.location.pathname === `/${userLang}/`;

  const updatedLinks = useMemo(() => {
    if (navBarContent) {
      let links = navBarContent.links.filter((item) => {
        if (authToken?.access_token && item.originalLabel === navLinks.logged[item.originalLabel as keyof typeof navLinks.logged]) {
          return item;
        } else if (!authToken?.access_token && item.originalLabel === navLinks.unlogged[item.originalLabel as keyof typeof navLinks.logged]) {
          return item;
        }
      }).filter(link => {
        if (excludeLoginButtonRoutes.includes(endRoute)) {
          return link.originalLabel !== navLinks.unlogged['Join us as consultant']
        } else {
          return link
        }
      });
      return links;
    } else {
      return [];
    }
  }, [navBarContent, authToken, location?.pathname]);

  useEffect(() => {
    if (!location.pathname.includes(`/${userLang}/chat`)) {
      dispatch(setIsConsultantInMeeting(false))
      dispatch(setSelectedCall({ cnsltId: 0, type: 'audio', clientToken: '', roomURL: '', url: '' }))
    }
    if (!location.pathname.includes(`/account-delete`) && !location.pathname.includes(`/login`)) {
      localStorage?.removeItem('action');
    }
  }, [location, userLang])

  const handleCallingModal = (status: boolean, type?: callingOptions) => {
    setShowCallModal(status);
    if (!status) {
      setIncomingCallInfo(undefined);
      dispatch(setIncomingCallConsultationId(""));
    }
  };

  const onMessageListener = () => {
    if (messaging) {
      new Promise((resolve) => {
        onMessage(messaging, async (payload) => {
          // console.log(payload);
          // console.log('test => ', isConsultantInsideMeeting, payload);
          let incomingCallPayload = payload.data;
          setIncomingCallInfo({
            call_type: incomingCallPayload?.call_type as callingOptions,
            consultation_id: incomingCallPayload?.consultation_id as string || '',
            pageURL: incomingCallPayload?.pageURL as string || '',
            pageURLlocal: incomingCallPayload?.pageURLlocal as string || '',
            pageURLProd: incomingCallPayload?.pageURLProd as string || ''
          });
          // console.log('incoming call => ', incomingCallPayload);

          dispatch(setIncomingCallConsultationId(incomingCallPayload?.consultation_id as string || ''));
          handleCallingModal(true);

          if (isConsultantInsideMeeting) {
            handleCallingModal(false);
          }
          resolve(payload);
        });
      });
    } else {
      return
    }
  }

  useEffect(() => {
    onMessageListener()
  }, [isConsultantInsideMeeting])

  const getNotificationPermission = async () => {
    try {
      requestPermission();
    } catch (error) {
      // console.log('An error occuren while storing FCM token');
    }
  };

  useEffect(() => {
    authToken.access_token && getNotificationPermission();
  }, [authToken]);

  useEffect(() => {
    (authToken?.access_token) && dispatch(getUserDetailsThunk());
  }, [dispatch, authToken]);

  useEffect(() => {
    let pathname = window.location.pathname;
    if (Object.keys(OtherBrokenLinks).includes(pathname)) navigate(OtherBrokenLinks[pathname]);
  }, []);

  useEffect(() => {
    if (lang !== userLang) {
      const option = getLangOption(lang!);
      if (option) changeLang2(option.value);
    }
  }, [lang]);

  const redirectToLangUrl = () => {
    // debugger;
    let pathname = window.location.pathname;
    if (window.location?.search) {
      pathname = pathname + window.location?.search;
    }
    if (checkIfLangExist(pathname)) {
      navigate(`/${userLang}${pathname}`);
    } else {
      return;
    }
  };

  useEffect(() => {
    redirectToLangUrl();
    dispatch(getNavBarContentThunk());
    dispatch(getCategoryListThunk());
  }, [userLang, dispatch]);

  const renderTitle = (val: string) => {
    return (
      <>
        <LangSvg />
        {getLangOption(val)?.label}
      </>
    );
  };

  const replacePathName = (val: string) => {
    let pathname = window.location.pathname;
    if (window.location?.search) {
      pathname = pathname + window.location?.search;
    }
    if (pathname.includes('/en')) {
      pathname = pathname.replaceAll('/en', `/${val}`);
    }
    if (pathname.includes('/ar')) {
      pathname = pathname.replace('/ar', `/${val}`);
    }
    if (pathname.includes('/fr')) {
      pathname = pathname.replace('/fr', `/${val}`);
    }
    navigate(pathname);
  };

  const updateLangOnLocal = (value: SUPPORTED_LANGS) => {
    dispatch(changeContentLang(value));
    setLangInLS(value);
    replacePathName(value);
  }

  const changeLang = async (value: SUPPORTED_LANGS) => {
    if (authToken?.access_token) {
      const payload = { locale: value } as iUpadteNamePayload;
      await dispatch(changUserLocaleOnServerThunk(payload));
      updateLangOnLocal(value)
    } else {
      updateLangOnLocal(value)
    }
  };

  const changeLang2 = (value: SUPPORTED_LANGS) => {
    dispatch(changeContentLang(value));
    setLangInLS(value);
  };

  const langBtnHandle = (val: string) => {
    const option = getLangOption(val);
    if (option) changeLang(option.value);
  };

  function createRouteHandler(id: number, name: string) {
    navigate(`${userLang}/category/${id}/${name?.toLowerCase().replaceAll(' ', '-')}`);
    setShow(false);
  }

  const getLangOption = (locale: string) => navBarContent.langOptions.find((lang) => locale === lang.value);

  const toggleOffCanvas = () => {
    setShow((show) => !show);
  };

  const closeOffCanvas = () => {
    setShow(false);
  };

  return (
    <>
      {!isConsultantInsideMeeting && (
        <ConsultantCallModal incomingCallInfo={incomingCallInfo} handleCallingModal={handleCallingModal} type={incomingCallInfo?.call_type || 'audio'} callModal={callModal} />
      )}

      {bgImgCondition ? (
        <div className="bg-image">
          <picture>
            <source
              width={345}
              height={294}
              media="(max-width:425px)"
              srcSet={images.other['homeBGgradient-main__mobile']}
            />
            <source
              width={545}
              height={463}
              media="(min-width:425px)"
              srcSet={images.other['homeBGgradient-main']}
            />
            <img src={images.other['homeBGgradient-main__mobile']} alt="hero-img" className="heroBanner__mainImg" />
          </picture>
        </div>
      ) : null}
      <header className={`header ${headerClass}`}>
        {['lg'].map((expand, index) => (
          <Navbar
            style={{ borderBottom: headerBorder ? `.5px solid ${colors['primaryLightGray-2']}` : 'none' }}
            key={index}
            fixed="top"
            expand={expand}
          >
            <Container>
              <div className="langMobile">
                <NavDropdown title={renderTitle(userLang)} id="lang-nav-dropdown" className="langDropdown">
                  <NavDropdown.Item id="ar" eventKey="ar" onClick={() => langBtnHandle('ar')}>
                    عربي
                  </NavDropdown.Item>
                  <NavDropdown.Item id="en" eventKey="en" onClick={() => langBtnHandle('en')}>
                    EN
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item id="fr" eventKey="fr" onClick={() => langBtnHandle('fr')}>
                    FRN
                  </NavDropdown.Item> */}
                </NavDropdown>
              </div>

              <Navbar.Brand className="mt-1">
                <Link to={`${userLang}/`} aria-label="Fasrly" rel="Fasrly noreferrer">
                  <LogoWhite />
                </Link>

                <div className="langDesktop">
                  <NavDropdown title={renderTitle(userLang)} id="lang-nav-dropdown" className="langDropdown langDesktop">
                    <NavDropdown.Item id="ar" eventKey="ar" onClick={() => langBtnHandle('ar')}>
                      عربي
                    </NavDropdown.Item>
                    <NavDropdown.Item id="en" eventKey="en" onClick={() => langBtnHandle('en')}>
                      EN
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item id="fr" eventKey="fr" onClick={() => langBtnHandle('fr')}>
                      FRN
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </div>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="d-lg-none" onClick={toggleOffCanvas} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
                className="navbarOffcanvas"
                show={show}
                onHide={toggleOffCanvas}
              >
                <Offcanvas.Header></Offcanvas.Header>
                <Offcanvas.Body className="align-items-lg-center">
                  <Nav className="me-auto mainNav-bar justify-content-center w-100">
                    {updatedLinks.length > 0 &&
                      updatedLinks.map((item, itemIndex) => {
                        return item.type === NAV_LINK_TYPES.LINK ? (
                          item.originalLabel === 'Blog' ? (
                            <Link
                              key={item.originalLabel}
                              className="nav-link d-flex align-items-center text-center"
                              to={`${linkRoutes[itemIndex]}`}
                              onClick={closeOffCanvas}
                            >
                              {item.label}
                            </Link>
                          ) : (
                            <Link
                              key={item.originalLabel}
                              className="nav-link d-flex align-items-center text-center"
                              to={`${userLang}${linkRoutes[itemIndex]}`}
                              onClick={closeOffCanvas}
                            >
                              {item.label}
                            </Link>
                          )
                        ) : (
                          <NavDropdown key={item.originalLabel} title={item.label} id="basic-nav-dropdown" className="align-items-center">
                            {categoryList.map((category) => (
                              <NavDropdown.Item
                                key={`${category.name_en}-${category.id}`}
                                className="dropdown-item"
                                onClick={() => createRouteHandler(category?.id, category.name_en)}
                              >
                                {category[`name_${userLang}`]}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        );
                      })}
                  </Nav>
                  <Nav style={{ width: excludeLoginButtonRoutes.includes(endRoute) ? 200 : 'auto' }} className="sideNavBar align-items-center">
                    {!authToken.access_token || isEmpty(user) ? (
                      <>
                        <NavButton width={excludeLoginButtonRoutes.includes(endRoute) ? 'auto' : '130px'}>
                          {excludeLoginButtonRoutes.includes(endRoute) ? (
                            <ReactLink
                              className="loginButtonLink px-3 w-100 ms-0 mt-1 mt-xl-0"
                              style={{ justifyContent: 'center', lineHeight: '18px' }}
                              to={ROUTE_PATHS.REGISTER_CONSULTANT.replace(':lang', userLang)}
                              content={navBarContent.joinAsConsultant}
                            />
                          ) : (
                            <ReactLink
                              className="loginButtonLink"
                              style={{ justifyContent: 'center' }}
                              to={ROUTE_PATHS.LOGIN.replace(':lang', userLang)}
                              onClick={closeOffCanvas}
                              content={navBarContent.navButton.loginBtn}
                            />
                          )}
                        </NavButton>
                      </>
                    ) : (
                      <UserMenuLinks />
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </header>
      <Outlet />
    </>
  );
};

export default NavBar;
