import { eUserType } from 'types/userInterfaces';
import { SUPPORTED_LANGS } from '../contants/commonConstants';

export enum LOCAL_STORAGE_KEYS {
  LANG = 'LANG',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  USER_TYPE = 'USER_TYPE',
  USER_ID = 'USER_ID',
  SELECTED_PACKAGE = 'SELECTED_PACKAGE',
}

export const setItemInLocalStorage = (key: LOCAL_STORAGE_KEYS, value: string) =>
  localStorage.setItem(key, value);

export const getItemFromLocalStorage = (key: LOCAL_STORAGE_KEYS): string => {
  const temp = localStorage.getItem(key);
  return temp ? temp : '';
};

export const getLangFromLS = () =>
  getItemFromLocalStorage(LOCAL_STORAGE_KEYS.LANG) as SUPPORTED_LANGS;

export const setLangInLS = (value: string) => {
  setItemInLocalStorage(LOCAL_STORAGE_KEYS.LANG, value);
};

export const setAccessTokenInLS = (value: string) =>
  setItemInLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, value);
export const getAccessTokenFromLS = () =>
  getItemFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);


export const setUserType= (value: string) => setItemInLocalStorage(LOCAL_STORAGE_KEYS.USER_TYPE, value);
export const getUserType = () : eUserType => getItemFromLocalStorage(LOCAL_STORAGE_KEYS.USER_TYPE) as eUserType;

export const setUserId= (value: string) =>
  setItemInLocalStorage(LOCAL_STORAGE_KEYS.USER_ID, value);
export const getUserId = () =>
  getItemFromLocalStorage(LOCAL_STORAGE_KEYS.USER_ID);

export const removeAccessTokenFromLS = () =>{
  let lang = getLangFromLS();
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.clear();
  setLangInLS(lang)
}