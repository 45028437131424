import { initializeApp } from 'firebase/app';
import { getToken, getMessaging } from 'firebase/messaging';
import { REACT_APP_FIREBASE_MESSAGING_KEY } from 'contants/envConstants';

const firebaseConfig = {
  apiKey: "AIzaSyAzf8w8j2IDWXR_WiFYTA650PEmHRK5uUU",
  authDomain: "fasrly-production.firebaseapp.com",
  projectId: "fasrly-production",
  storageBucket: "fasrly-production.appspot.com",
  messagingSenderId: "98177674608",
  appId: "1:98177674608:web:f3215f7e0d43230f9af3b5",
  measurementId: "G-P6SX6DYKDB"
};

export const app = initializeApp(firebaseConfig);
export const messaging = navigator?.serviceWorker && getMessaging(app);

export const requestPermission = async () => {
  if (Notification.permission === 'granted') {
    return
  } else {
    Notification.requestPermission();
  }
};

export const getTokenFn = async () => {
  return messaging ? await getToken(messaging, {
    vapidKey: REACT_APP_FIREBASE_MESSAGING_KEY,
  }) : ''
}
