import axios, { AxiosInstance, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { REACT_APP_BASE_API_URL } from '../contants/envConstants';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  responseType: 'json',
  validateStatus: (status: number) => status >= 200,
  headers: {
    'Content-type': 'application/json',
  },
});

type axiosMethodTypes = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

export const apiManager = {
  request: async (
    url: string,
    body: unknown,
    method: axiosMethodTypes,
    accessToken?: string,
    baseURL = REACT_APP_BASE_API_URL,
    media?: boolean,
    sendBirdToken?: string
  ): Promise<AxiosResponse> => {    
    const headers: RawAxiosRequestHeaders  = structuredClone(
      axiosInstance.defaults.headers
    );
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    if (media) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    if (sendBirdToken) {
      headers["Api-Token"] = sendBirdToken;
    }
    try {
      return axiosInstance({
        method: method,
        url: url,
        data: body,
        baseURL: baseURL,
        headers: headers,
      });
    } catch (e) {
      throw new Error(e as string);
    }
  },
};
