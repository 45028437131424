import { REACT_APP_ASSETS_BASE_URL } from 'contants/envConstants';
import { iNavLinksData, iSchedule } from 'types/commonInterfaces';
import { iScheduleSlots } from 'types/consultantionInterface';

interface IBrokenLinks {
  [key: string]: Object;
}

export const sectionIds = {
  about: '#aboutContainer',
};

export const navbarDummyData = {
  links: [
    {
      type: 'link',
      label: 'Home',
      originalLabel: 'Home',
    },
    {
      type: 'dropdown',
      label: 'Categories',
      originalLabel: 'Categories',
      items: [
        'Mental Counseling',
        'Marriage Emotional Counseling',
        'Life Coach',
        'Dream Interpretation',
        'Personality Analysis',
        'Family Counseling',
        'Career Coach',
        'Nutrition Specialist',
      ],
    },
    {
      type: 'link',
      label: 'Blog',
      originalLabel: 'Blog',
    },
    {
      type: 'link',
      label: 'Join us as consultant',
      originalLabel: 'Join us as consultant',
    },
  ],
};

export const colors = {
  primaryBlue: '#1B75BB',
  'primaryHoverBlue-opacity-2': '#1b75bc2e',
  'primaryBlue-opacity-5': '#1B75BB50',
  primaryLightBlue: '#00ADEE',
  '$primaryBlue-opacity-1': '#00ADEE10',
  'primaryLightBlue-2-opacity-2': '#40C2F220',
  secondaryBlue: '#2457B9',
  'primaryLightBlue-3': '#0290FF',
  'primaryWhite-opacity-5': '#F7F7F850',
  white: '#FFF',
  'white-opacity-75': '#ffffff75',
  'white-opacity-2': '#ffffff20',
  'primaryWhite-1': '#F0F0F1',
  'primaryWhite-2': '#F4F5F6',
  black: '#000',
  ' $black-opacity-1': '#00000010',
  primaryWhite: '#F7F7F8',
  'primaryLightBlue-2': '#40C2F2',
  primaryOrange: '#ED943B',
  secondaryOrange: '#FEBA0F',
  'primaryOrange-opacity-2': '#ED943B20',
  primaryLightGray: '#BFD2E1',
  'primaryLightGray-1': '#A6A8AB',
  'primaryLightGray-2': '#A6A8AB',
  'primaryLightGray-3-opacity-1': '#A6A8AB10',
  'primaryLightGray-3-opacity-2': '#A6A8AB20',
  'primaryLightGray-3-opacity-5': '#A6A8AB50',
  '$primaryLightGray-4': '#D9D9D9',
  secondaryGray: '#6D6E70',
  'secondaryGray-opacity-2': '#6D6E7020',
  '$secondaryGray-opacity-05': '#6D6E7005',
  other_1: '#C5A919',
  primaryDrakPink: '#A155A5',
  primaryLightGreen: '#69C677',
  'primaryLightGreen-1': '#E1F3E3',
  primarySkinColor: '#C4A484',
  secondaryRed: '#F27068',
  'secondaryRed-2': '#D33',
  primaryRed: '#FCE2E1',
  'secondaryRed-opacity-2': '#F2706820',
  dividerColor: '#ECECEC',
};

export const OtherBrokenLinks: IBrokenLinks = {
  '/lang/ar': '/ar',
  '/lang/er': '/er',

  '/ar/Privacy': '/ar/privacy-policy',
  '/en/Privacy': '/en/privacy-policy',
  '/en/register': '/en/signup',
  '/ar/register': '/ar/signup',

  '/en/mobile': '/en/mobile-marketing',
  '/ar/mobile': '/ar/mobile-marketing',
};

export const CategoryBrokenLinks: IBrokenLinks = {
  '/ar/category/3/marriage-emotional-counseling': '/ar/category/6/emotional-and-marriage-counselling',
  '/en/category/3/marriage-emotional-counseling': '/en/category/6/emotional-and-marriage-counselling',
  '/ar/category/3/marriage_emotional_counseling': '/ar/category/6/emotional-and-marriage-counselling',
  '/en/category/3/marriage_emotional_counseling': '/en/category/6/emotional-and-marriage-counselling',

  '/ar/category/5/mental_counseling': '/ar/category/1/mental-health',
  '/en/category/5/mental_counseling': '/en/category/1/mental-health',

  '/ar/category/6/life_coach': '/ar/category/2/life-coaching',
  '/en/category/6/life_coach': '/en/category/2/life-coaching',
  '/ar/category/6/life-coach': '/ar/category/2/life-coaching',
  '/en/category/6/life-coach': '/en/category/2/life-coaching',

  '/ar/category/8/personality_analysis': '/ar/category/3/personality-analysis',
  '/en/category/8/personality_analysis': '/en/category/3/personality-analysis',
  '/ar/category/8/personality-analysis': '/ar/category/3/personality-analysis',
  '/en/category/8/personality-analysis': '/en/category/3/personality-analysis',

  '/en/category/7/dream-interpretation': '/en/category/8/dream-interpretation',
  '/ar/category/7/dream-interpretation': '/ar/category/8/dream-interpretation',
  '/en/category/7/dream_interpretation': '/en/category/8/dream-interpretation',
  '/ar/category/7/dream_interpretation': '/ar/category/8/dream-interpretation',

  '/en/category/9/family_counseling': '/en/category/4/family-&-parenting-counselling',
  '/ar/category/9/family_counseling': '/ar/category/4/family-&-parenting-counselling',
  '/en/category/9/family-counseling': '/en/category/4/family-&-parenting-counselling',
  '/ar/category/9/family-counseling': '/ar/category/4/family-&-parenting-counselling',

  '/en/category/12/career_coach': '/en/category/7/career-coaching',
  '/ar/category/12/career_coach': '/ar/category/7/career-coaching',
  '/en/category/12/career-coach': '/en/category/7/career-coaching',
  '/ar/category/12/career-coach': '/ar/category/7/career-coaching',

  '/en/category/13/nutrition_specialist': '/en/category/12/nutrition-counselling',
  '/ar/category/13/nutrition_specialist': '/ar/category/12/nutrition-counselling',
  '/en/category/13/nutrition-specialist': '/en/category/12/nutrition-counselling',
  '/ar/category/13/nutrition-specialist': '/ar/category/12/nutrition-counselling',
};

// const baseImgsURL = 'https://faserly-app.s3.amazonaws.com/';
const baseAssetsURLCloudfront = REACT_APP_ASSETS_BASE_URL + '/img/web/';

export const images = {
  homeImgs: {
    heroImg: baseAssetsURLCloudfront + 'home/heroImg.webp',
    heroImg__mob: baseAssetsURLCloudfront + 'home/heroImg__mob.webp',
    avatar1__mob: baseAssetsURLCloudfront + 'home/avatar1__mob.webp',
    avatar1: baseAssetsURLCloudfront + 'home/avatar1.webp',
    avatar2__mob: baseAssetsURLCloudfront + 'home/avatar2__mob.webp',
    avatar2: baseAssetsURLCloudfront + 'home/avatar2.webp',
    avatar3: baseAssetsURLCloudfront + 'home/avatar3.webp',
    avatar3__mob: baseAssetsURLCloudfront + 'home/avatar3__mob.webp',
    avatar4: baseAssetsURLCloudfront + 'home/avatar4.webp',
    avatar4__mob: baseAssetsURLCloudfront + 'home/avatar4__mob.webp',
    clock__mob: baseAssetsURLCloudfront + 'home/clock__mob.webp',
    clock: baseAssetsURLCloudfront + 'home/clock.webp',
    cupBig__mob: baseAssetsURLCloudfront + 'home/cupBig__mob.webp',
    cupBig: baseAssetsURLCloudfront + 'home/cupBig.webp',
    cupSmall: baseAssetsURLCloudfront + 'home/cupSmall.webp',
    cupSmall__mob: baseAssetsURLCloudfront + 'home/cupSmall__mob.webp',
    fruits: baseAssetsURLCloudfront + 'home/fruits.webp',
    fruits__mob: baseAssetsURLCloudfront + 'home/fruits__mob.webp',
    tree: baseAssetsURLCloudfront + 'home/tree.webp',
    tree__mob: baseAssetsURLCloudfront + 'home/tree__mob.webp',
    tree__small: baseAssetsURLCloudfront + 'home/tree__small.webp',
  },
  testimonialImages: {
    testGroup: baseAssetsURLCloudfront + 'testimonialImages/testGroup.webp',
  },
  aboutImgs: {
    aboutPhone__mob: baseAssetsURLCloudfront + 'aboutImgs/aboutPhone__mob.webp',
    aboutPhone: baseAssetsURLCloudfront + 'aboutImgs/aboutPhone.webp',
    about_bg_mob: baseAssetsURLCloudfront + 'aboutImgs/about_bg_mob.webp',
    about_bg: baseAssetsURLCloudfront + 'aboutImgs/about_bg.webp',
  },
  other: {
    visaMaster__latest: baseAssetsURLCloudfront + 'other/visaMaster__latest.webp',
    arrowLeftSlider: baseAssetsURLCloudfront + 'other/arrowLeftSlider.webp',
    arrowRightSlider: baseAssetsURLCloudfront + 'other/arrowRightSlider.webp',
    intro_phone_2: baseAssetsURLCloudfront + 'other/intro_phone_2.webp',
    boxes_2: baseAssetsURLCloudfront + 'other/boxes_2.webp',
    boxes_1: baseAssetsURLCloudfront + 'other/boxes_1.webp',
    bgDotsSliderLeft: baseAssetsURLCloudfront + 'other/bgDotsSliderLeft.webp',
    bgDotsSliderRight: baseAssetsURLCloudfront + 'other/bgDotsSliderRight.webp',
    boxes_2__mobile: baseAssetsURLCloudfront + 'other/boxes_2__mobile.webp',
    boxes_1__mobile: baseAssetsURLCloudfront + 'other/boxes_1__mobile.webp',
    messageSuccess: baseAssetsURLCloudfront + 'other/messageSuccess.webp',
    aboutWithShadow: baseAssetsURLCloudfront + 'other/aboutWithShadow.webp',
    inAppImg: baseAssetsURLCloudfront + 'other/inAppImg.png',
    directCallmg: baseAssetsURLCloudfront + 'other/directCallmg.png',
    "homeBGgradient-main__mobile": baseAssetsURLCloudfront + 'other/homeBGgradient-main__mobile.webp',
    'homeBGgradient-main': baseAssetsURLCloudfront + 'other/homeBGgradient-main.webp'
  },
};

export const categorySEO = [
  {
    title: 'mental-health',
    metaTitle:'أفضل منصة عربية للعناية بالصحة النفسية وبأسعار معقولة | فسرلي',
    metaDesc:'جلسات اونلاين فردية مع استشاريين خبراء في العالم العربي لإدارة التوتر والقلق والصدمات النفسية و استشارات الاكتئاب والصحة النفسية بشكل عام.',
  },
  {
    title: 'life-coaching',
    metaTitle: 'تطبيق تنمية ذاتية يسمح لك بالتواصل مع مدربي حياة  | فسرلي',
    metaDesc:'حقق أهدافك وأحلامك من خلال خطط واستراتيجيات تفصيلية. اعطي تطويرك الشخصي أولوية من خلال العمل مع مدربين فسرلي للحياة .',
  },
  {
    title: 'career-coaching',
    metaTitle: 'التطبيق رقم 1 لخدمات الإرشاد المهني عبر الإنترنت | فسرلي',
    metaDesc:
      'ارتقي بحياتك المهنية من خلال استشارة مرشدين ذوي خبرة. يقدم فسرلي  أفضل المرشدين المهنيين لمساعدتك على التقدم في حياتك المهنية.',
  },
  {
    title: 'emotional-and-marriage-counselling',
    metaTitle: 'قم بإحياء علاقتك بشريكك بمساعدة مستشارين متخصصين  | فسرلي',
    metaDesc:'احصل على استشارات زوجية من متخصصين لمساعدتك على تنمية علاقتك مع شريك حياتك. يمكنك حجز جلستك في أي وقت مع فريقنا من الاستشاريين.',
  },
  {
    title: 'nutrition-counselling',
    metaTitle: 'احصل على خطط غذائية خاصة بك من كوتش تغذية علاجية عبر الانترنت | فسرلي',
    metaDesc:'تعرف على الأطعمة المناسبة لحالتك الصحية من خلال مستشاري فسرلي المعتمدين. تتوفر أنظمة غذائية لاضطرابات الأكل والأمراض المزمنة.',
  },
  {
    title: 'family-&-parenting-counselling',
    metaTitle: 'افضل خدمات الاستشارة الاسرية بخصوصية وعبر الانترنت | فسرلي',
    metaDesc:'حسن علاقتك مع أفراد عائلتك من خلال استشاراتنا الاسرية، ابدا بحل  المشاكل التي تواجهك مع أفراد عائلتك وعزز التواصل مع طفلك بطريقة صحية وإيجابية اليوم.'
  },
  {
    title: 'dream-interpretation',
    metaTitle: 'أفضل مفسرين احلام متخصصين في العالم العربي - منصة فسرلي',
    metaDesc: 'نحن هنا لمساعدتك في فهم معاني أحلامك عن طريق خبراء تفسير الأحلام. نحن واثقون من قدرتنا على مساعدتك في فهم عقلك الباطن بشكل أفضل.'
  },
  {
    title: 'personality-analysis',
    metaTitle: 'اكتشف نوع شخصيتك من خلال اختبار تحليل الشخصية على منصة فسرلي',
    metaDesc:'اكتشف نوع شخصيتك من خلال اختبار الشخصية مع خبراء عبر الإنترنت. تعرف على دوافعك وعواطفك وسلوكياتك في المواقف المختلفة.'
  },
];

export const failStatusCodes = ['fail', 'Fail', false];

export const navLinks: iNavLinksData = {
  logged: {
    Dashboard: 'Dashboard',
    Blog: 'Blog',
    Categories: 'Categories'
  },
  unlogged: {
    Dashboard: 'Dashboard',
    Blog: 'Blog',
    Categories: 'Categories',
    "Join us as consultant": "Join us as consultant"
  }
};

export const fbEventNames = {
  'Contact': {
    title: 'Contact',
    params: {}
  },
  'Complete registration': {
    title: 'Complete registration',
    params: {
      'Registration Method': 'Registration Method'
    }
  },
  'View content': {
    title: 'View content',
    params: {
      'Content ID': 'Content ID',
      'Content Type': 'Content Type'
    }
  },
  'Initiate checkout': {
    title: 'Initiate checkout',
    params: {
      'Content ID': 'Content ID',
      'Content Type': 'Content Type',
      'Currency': 'Currency',
      'valueToSum': 'valueToSum'
    }
  },
  'Rate': {
    title: 'Rate',
    params: {
      'MaxRating Value': 'MaxRating Value',
      'Content Type': 'Content Type',
      'valueToSum': 'valueToSum'
    }
  },
  'Purchase': {
    title: 'Purchase',
    params: {
      'Content ID': 'Content ID',
      'Content Type': 'Content Type',
      'Currency': 'Currency',
      'valueToSum': 'valueToSum'
    }
  }
}
export const scheduleInitialState: iSchedule = {
  "schedule": [
    {
      "day": "SUNDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "1"
    },
    {
      "day": "MONDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "1"
    },
    {
      "day": "TUESDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "1"
    },
    {
      "day": "WEDNESDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "1"
    },
    {
      "day": "THURSDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "0"
    },
    {
      "day": "FRIDAY",
      "slots": [
        { start: '10:00:00', end: '12:00:00' }
      ],
      "active": "0"
    },
    {
      "day": "SATURDAY",
      "slots": [{ start: '10:00:00', end: '12:00:00' }],
      "active": "0"
    }
  ]
}


export const updatedData: iScheduleSlots[] = [
  {
    date: "2023-10-06",
    day: 'SUNDAY',
    active: true,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [
          { "slot_start_time": "10:00", "slot_end_time": "11:15" },
          { "slot_start_time": "11:15", "slot_end_time": "12:15" },
          { "slot_start_time": "12:15", "slot_end_time": "13:15" }
        ],
      },
      {
        packageId: 30,
        slots: [],
      }
    ]
  },
  {
    date: "2023-10-07",
    day: 'MONDAY',
    active: false,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [],
      },
      {
        packageId: 30,
        slots: [],
      }
    ]
  },
  {
    date: "2023-10-08",
    day: 'TUESDAY',
    active: false,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [],
      },
      {
        packageId: 30,
        slots: [],
      }
    ]
  },
  {
    date: "2023-10-09",
    day: 'WEDNESDAY',
    active: false,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [],
      },
      {
        packageId: 30,
        slots: [],
      }
    ]
  },
  {
    date: "2023-10-10",
    day: 'THURSDAY',
    active: true,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" }
        ],
      },
      {
        packageId: 30,
        slots: [
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" }
        ],
      }
    ]
  },
  {
    date: "2023-10-11",
    day: 'FRIDAY',
    active: true,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" }
        ],
      },
      {
        packageId: 30,
        slots: [
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" },
          { "slot_start_time": "10:00", "slot_end_time": "10:15" }
        ],
      }
    ]
  },
  {
    date: "2023-10-12",
    day: 'SATURDAY',
    active: false,
    packagesAndSlots: [
      {
        packageId: 15,
        slots: [],
      },
      {
        packageId: 30,
        slots: [],
      }
    ]
  },
];

export const excludeLoginButtonRoutes = ['login', 'signup', 'verify-otp', 'password-reset', 'verify-email'];
