import React from 'react';

const PhoneCallSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill="currentColor" fillOpacity="0.1" />
      <path
        d="M24.9161 21.1901V23.4401C24.9169 23.6489 24.8741 23.8557 24.7905 24.0471C24.7068 24.2385 24.5841 24.4103 24.4301 24.5515C24.2762 24.6927 24.0945 24.8002 23.8966 24.8671C23.6988 24.934 23.4891 24.9589 23.2811 24.9401C20.9732 24.6893 18.7563 23.9007 16.8086 22.6376C14.9965 21.4861 13.4601 19.9497 12.3086 18.1376C11.0411 16.181 10.2523 13.9533 10.0061 11.6351C9.98735 11.4277 10.012 11.2186 10.0785 11.0213C10.1449 10.8239 10.2518 10.6426 10.3922 10.4888C10.5326 10.335 10.7034 10.2121 10.8939 10.128C11.0844 10.0438 11.2903 10.0003 11.4986 10.0001H13.7486C14.1126 9.99649 14.4654 10.1254 14.7414 10.3627C15.0174 10.6001 15.1976 10.9297 15.2486 11.2901C15.3436 12.0101 15.5197 12.7171 15.7736 13.3976C15.8745 13.666 15.8963 13.9578 15.8365 14.2382C15.7767 14.5187 15.6377 14.7762 15.4361 14.9801L14.4836 15.9326C15.5513 17.8102 17.1059 19.3649 18.9836 20.4326L19.9361 19.4801C20.14 19.2784 20.3975 19.1395 20.6779 19.0796C20.9584 19.0198 21.2501 19.0417 21.5186 19.1426C22.199 19.3965 22.906 19.5726 23.6261 19.6676C23.9904 19.719 24.3231 19.9025 24.561 20.1832C24.7988 20.4639 24.9252 20.8223 24.9161 21.1901Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneCallSvg;
