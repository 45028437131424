import React from 'react';
import { Button } from 'react-bootstrap';

interface iIconButton {
  className?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
  loadingIcon?: React.ReactElement;
  content: string;
  iconSide?: string;
  style?: React.CSSProperties;
  id?: string;
}

const IconButton = (props: iIconButton) => {

  return (
    <Button
      style={props.style ? props.style : {}}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      id={props?.id || 'iconButton'}
      className={`reusableIconButton d-flex justify-content-center align-items-center gap-2 ${props.className ? props.className : ''}`}
      variant="primary"
    >
      {props.loading ? props.loadingIcon : props.icon ? props.icon : null} {props.content}
    </Button>
  );
};

export default IconButton;
