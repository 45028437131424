import { iCallLog, iCouponObj, iScheduleSlots, tSendConsultationBasic } from './../../types/consultantionInterface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  iConsultationPayload,
  iConsultation,
  iConsultationReplies,
  iStatus,
  IUpdateStatus,
  IActiveChat,
  iConsultationRepliesV2,
  iSendConsultation,
  iIsConsultantInsideMeeting,
} from '../../types/consultantionInterface';
import {
  consultationsThunk,
  getConsultationListThunk,
  getConsultationRepliesListThunk,
  consultationsRepliesThunk,
  deleteConsultationThunk,
  getConsultationStatusThunk,
  getSendBirdSessionTokenThunk,
  updateConsultationStatusThunk,
  getActiveChatsThunk,
  getArchivedChatsThunk,
  getCanceledChatsThunk,
  viewConsultationChatsThunk,
  getDirectCallLogsThunk,
  getConsultationAvailableSlotsPerDay,
  ChckCouponThunk,
} from './consultation-thunks';
import { iError, iPaginationMeta, tRoomJoin } from '../../types/commonInterfaces';
import { getUserDetailsThunk } from 'store/user-slice/user-thunks';

export interface iConsultationStoreState {
  consultation: iConsultationPayload;
  consltations: iConsultation[];
  replies: iConsultationRepliesV2[];
  error: iError;
  success: boolean;
  pendingChats: number;
  consultationsStatus: iStatus;
  id: number;
  paginationMeta: iPaginationMeta;
  userSessionToken: string;
  userSessionExpireTime: number | null;
  notSignedUp: boolean;
  updateStatus: IUpdateStatus;
  activeChats: IActiveChat;
  archivedChats: IActiveChat;
  loading?: boolean;
  loadingFor?: '';
  sendConsultation: iSendConsultation;
  selectedCall: tRoomJoin;
  isConsultantInsideMeeting: iIsConsultantInsideMeeting;
  incomingCallConsultationId: string;
  direcCallLogs: iCallLog;
  basicAttachments: tSendConsultationBasic;
  availableSlots: iScheduleSlots[];
  couponCode: iCouponObj;
}

const DEFAULT_ERROR_MESSAGE = 'Error';

const initialState: iConsultationStoreState = {
  consultation: {} as iConsultationPayload,
  consltations: [],
  error: {} as iError,
  replies: [],
  success: false,
  pendingChats: 0,
  consultationsStatus: {} as iStatus,
  id: 0,
  paginationMeta: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 1,
  },
  userSessionToken: '',
  userSessionExpireTime: null,
  notSignedUp: true,
  updateStatus: {} as IUpdateStatus,
  activeChats: {} as IActiveChat,
  archivedChats: {} as IActiveChat,
  loading: false,
  sendConsultation: {} as iSendConsultation,
  selectedCall: {} as tRoomJoin,
  isConsultantInsideMeeting: false,
  incomingCallConsultationId: '',
  direcCallLogs: {} as iCallLog,
  basicAttachments: {} as tSendConsultationBasic,
  availableSlots: [] as iScheduleSlots[],
  couponCode: {} as iCouponObj,
};

const consultationSlice = createSlice({
  name: 'consultation',
  initialState,
  reducers: {
    clearError(state) {
      state.error = {} as iError;
      state.success = false;
    },
    makeUpdateStatusDefault(state) {
      state.updateStatus = {} as IUpdateStatus;
    },
    setBasicConsultationAttachments(state, action: PayloadAction<tSendConsultationBasic>) {
      state.basicAttachments = action.payload;
    },
    setSendConsultationAttachments(state, action: PayloadAction<iSendConsultation>) {
      state.sendConsultation = action.payload as iSendConsultation;
    },
    setIsConsultantInMeeting(state, action: PayloadAction<iIsConsultantInsideMeeting>) {
      state.isConsultantInsideMeeting = action.payload;
    },
    setIncomingCallConsultationId(state, action: PayloadAction<string>) {
      state.incomingCallConsultationId = action.payload;
    },
    setSelectedCall(state, action: PayloadAction<tRoomJoin>) {
      state.selectedCall = action.payload;
    },
    removeCoupon(state, actions) {
      state.couponCode = {} as iCouponObj;
    }
  },
  extraReducers(builder) {
    builder.addCase(consultationsThunk.pending, (state, action) => {
      state.error = {} as iError;
      state.loading = true;
    });
    builder.addCase(consultationsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.success = true;
      state.id = action.payload.id;
      state.loading = false;
    });
    builder.addCase(consultationsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
      state.loading = false;
    });
    builder.addCase(getConsultationListThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.consltations = action.payload.consultations.length ? action.payload.consultations : [];
      state.pendingChats = action.payload.consultations?.filter((x) => x.replies_count === 1)?.length;
      state.paginationMeta = action.payload.meta;
    });
    builder.addCase(getConsultationListThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getConsultationRepliesListThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.replies = action.payload.consultationsReplies?.length ? action.payload.consultationsReplies : [];
    });
    builder.addCase(getConsultationRepliesListThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });

    builder.addCase(consultationsRepliesThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.success = action.payload;
    });
    builder.addCase(consultationsRepliesThunk.rejected, (state, action) => {
      state.error.status = true;
      state.success = false;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(deleteConsultationThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.success = action.payload;
    });
    builder.addCase(deleteConsultationThunk.rejected, (state, action) => {
      state.error.status = true;
      state.success = false;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getConsultationStatusThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.consultationsStatus = action.payload.consultationsStatus;
    });
    builder.addCase(getConsultationStatusThunk.rejected, (state, action) => {
      state.error.status = true;
      state.success = false;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getSendBirdSessionTokenThunk.pending, (state, action) => {
      state.loading = true;
      state.error.status = false;
    });
    builder.addCase(getSendBirdSessionTokenThunk.fulfilled, (state, action) => {
      state.userSessionToken = action.payload.token;
      state.userSessionExpireTime = action.payload.expires_at;
      state.loading = false;
      state.error.status = false;
    });
    builder.addCase(getSendBirdSessionTokenThunk.rejected, (state) => {
      state.notSignedUp = false;
      state.loading = false;
      state.error.status = true;
    });
    builder.addCase(updateConsultationStatusThunk.pending, (state, action) => {
      state.error = {} as iError;
      state.loading = true;
    });
    builder.addCase(updateConsultationStatusThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.updateStatus = action.payload.updateStatus;
      state.success = true;
      state.loading = false;
    });
    builder.addCase(updateConsultationStatusThunk.rejected, (state, action) => {
      state.error.status = true;
      state.success = false;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
      state.loading = false;
    });
    builder.addCase(getActiveChatsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.activeChats = action.payload.activChats;
    });
    builder.addCase(getActiveChatsThunk.rejected, (state, action) => {
      state.activeChats = {} as IActiveChat;
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getArchivedChatsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.replies = action.payload.consultationsReplies?.length ? action.payload.consultationsReplies : [];
    });
    builder.addCase(getArchivedChatsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getCanceledChatsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.replies = action.payload.consultationsReplies?.length ? action.payload.consultationsReplies : [];
    });
    builder.addCase(getCanceledChatsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(viewConsultationChatsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.replies = action.payload.consultationsReplies?.length ? action.payload.consultationsReplies : [];
    });
    builder.addCase(viewConsultationChatsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getDirectCallLogsThunk.pending, (state, action) => {
      state.error = {} as iError;
      state.loading = true;
    });
    builder.addCase(getDirectCallLogsThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.loading = false;
      state.direcCallLogs = action.payload.log;
    });
    builder.addCase(getDirectCallLogsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
      state.loading = false;
    });
    builder.addCase(getConsultationAvailableSlotsPerDay.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.availableSlots = action.payload.result;
    });
    builder.addCase(getConsultationAvailableSlotsPerDay.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(ChckCouponThunk.pending, (state, action) => {
      state.error = {} as iError;
      state.loading = true;
    });
    builder.addCase(ChckCouponThunk.fulfilled, (state, action) => {
      state.error = {} as iError;
      state.couponCode = action.payload.result;
      state.loading = false;
    });
    builder.addCase(ChckCouponThunk.rejected, (state, action) => {
      state.error.status = true;
      state.loading = false;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
  },
});

export const {
  clearError,
  makeUpdateStatusDefault,
  setSendConsultationAttachments,
  setBasicConsultationAttachments,
  setIsConsultantInMeeting,
  setIncomingCallConsultationId,
  setSelectedCall,
  removeCoupon
} = consultationSlice.actions;
export default consultationSlice.reducer;
