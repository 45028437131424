import React, { useEffect, memo, useRef, RefObject, lazy } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';

import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { getHomePageContentThunk } from 'store/content-slice/content-thunks';
import { getCategoryListThunk } from 'store/category-slice/category-thunks';
import { colors, images, sectionIds } from 'utils/Data';
import IconButtonRightSide from 'layout/Button/IconButtonRightSide';

import BackArrowDeskTop from 'assets/images/ConsultantImgs/BackArrowDeskTop';
import RightArrowDeskTop from 'assets/images/ConsultantImgs/RightArrowDeskTop';
import './homeStyle.scss';

const Testimonials = lazy(() => import('components/Testimonials/Testimonials'));
const AboutLatest = lazy(() => import('components/about-fasrly/AboutLatest'));
const HomeCategoryContainer = lazy(() => import('container/HomeCategoryContainer/HomeCategoryContainer'));
const ConsultantList = lazy(() => import('components/Consultant-list/consultantList'));
const Footer = lazy(() => import('components/core-components/footer/Footer'))
const BlogsLatest = lazy(() => import('components/blogs/BlogsLatest'));

const Home = () => {
  const dispatch = useAppDispatch();
  const userLang = useAppSelector((state) => state.content.userLang);
  const aboutRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHomePageContentThunk());
    dispatch(getCategoryListThunk());
  }, [userLang, dispatch]);

  const SeoTags = () => {
    const title = 'فسرلي - أكبر منصة للاستشارات في الوطن العربي تضم نخبة من المتخصصين';
    const desc =
      ' منصة فسرلي هي منصة عربية تقدم لك نخبة من المتخصصين حيث يمكنك طلب استشارة بكل سهولة وخصوصية في الحياة الزوجية ، أو الأسرة ، أو الصحة النفسية ، وحتى تفسير الأحلام';
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={desc} />
      </Helmet>
    );
  };

  const scroller = (ref: RefObject<HTMLInputElement>) => {
    if (ref && ref.current) ref.current.scrollIntoView()
  }

  return (
    <>
      {SeoTags()}
      <main className="main">
        <section className="heroBanner">
          <Container className="d-flex align-items-lg-center justify-content-center px-3">
            <Row className="align-items-center">
              <Col md={6} className='heroBanner__headingsWrapper'>
                <h1 className="heroTitle">{userLang === 'ar' ? 'التنمية الشخصية بثقة وخصوصية' : 'Personal development with trust & privacy'}</h1>
                <IconButtonRightSide
                  onClick={() => scroller(aboutRef)}
                  content={userLang === 'ar' ? 'احصل على التطبيق' : 'Get the app'}
                  className="heroContent"
                  icon={userLang === 'en' ? <RightArrowDeskTop /> : <BackArrowDeskTop />}
                  style={{ color: colors.white, width: 'fit-content' }}
                />
              </Col>
              <Col md={6} className="d-flex justify-content-center heroBanner__ImgWrapper">
                <picture>
                  <source width={345} height={294} media="(max-width:425px)" srcSet={images.homeImgs.heroImg__mob} />
                  <source width={545} height={463} media="(min-width:425px)" srcSet={images.homeImgs.heroImg} />
                  <img src={images.homeImgs.heroImg__mob} alt="hero-img" className="heroBanner__mainImg" />
                </picture>
              </Col>
            </Row>
          </Container>
        </section>
        <HomeCategoryContainer />
        <ConsultantList />
        <Testimonials />
        <div className="aboutContainer" ref={aboutRef} id={sectionIds.about}>
          <AboutLatest />
        </div>
        <BlogsLatest />
      </main>
      <Footer />
    </>
  );
};

export default memo(Home);