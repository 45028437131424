import { memo } from 'react';
import CloseButtonSvg from 'assets/images/svg/CloseButtonSvg';
import DottedLineSvg from 'assets/images/svg/DottedLineSvg';
import FocusSvg from 'assets/images/svg/FocusSvg';
import PhoneCallSvg from 'assets/images/svg/PhoneCallSvg';
import VideoCallSvg from 'assets/images/svg/VideoCallSvg';
import BasicButtonSpinner from 'components/Loaders/BasicButtonSpinner';
import IconButton from 'layout/Button/IconButton';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { Modal, Stack } from 'react-bootstrap';
import { callingOptions } from 'types/commonInterfaces';
import { iConsultation } from 'types/consultantionInterface';
import { colors } from 'utils/Data';
import { setIsConsultantInMeeting } from 'store/consultation-slice/consultation-slice';
import './callingModal.scss';

interface iProps {
  callModal: { status: boolean; type: callingOptions };
  consultation: iConsultation;
  joinRoomLoading: boolean;
  consultant?: boolean;
  audioRequired?: boolean;
  handleSetSelectedCall: (type: callingOptions, consultation_id: number) => void;
  handleCallingModal: (status: boolean, type: callingOptions) => void;
}

const CallModalMobile = ({
  consultation,
  handleSetSelectedCall,
  handleCallingModal,
  callModal,
  joinRoomLoading,
  consultant,
  audioRequired,
}: iProps) => {
  const { userLang, consultationPageContent } = useAppSelector((state) => state.content);
  const dispatch = useAppDispatch();

  const modalTitle = () => {
    if (consultant) {
      return (
        <p className="__deep">
          {consultation.user?.[`name_${userLang}`]}{' '}
          {callModal.type === 'audio'
            ? consultationPageContent.startAudioCallTitle_v1__forConsultant
            : consultationPageContent.startAudioCallTitle_v1__forConsultant}
        </p>
      );
    } else {
      return (
        <p className="__deep">
          {callModal.type === 'audio' ? consultationPageContent.startAudioCallTitle_v1 : consultationPageContent.startVideoCallTitle_v1}
          &nbsp;
          {consultation.consultant[`name_${userLang}`] || consultation.consultant.name_en || consultation.consultant.name_ar}
        </p>
      );
    }
  };

  const iconButtonTitle = () => {
    if (consultant) {
      return callModal.type === 'audio'
        ? consultationPageContent.callModalButtonAudio_v1__forConsultant
        : consultationPageContent.callModalButtonVideo_v1__forConsultant;
    } else {
      return callModal.type === 'audio' ? consultationPageContent.callModalButtonAudio_v1 : consultationPageContent.callModalButtonVideo_v1;
    }
  };

  return (
    <Modal
      show={callModal.status}
      onHide={() => (consultant ? {} : handleCallingModal(false, 'audio'))}
      className={`deleteAccountmodal callModalMobile ${consultant ? 'consultantCallModalMobile' : ''}`}
    >
      {audioRequired && (
        <audio loop autoPlay>
          <source src={require('../../assets/audio/callingRingtone.mp3')} type="audio/mpeg" />
        </audio>
      )}
      <Modal.Header>
        <Modal.Title>
          <span
            onClick={() => {
              handleCallingModal(false, callModal.type);
              dispatch(setIsConsultantInMeeting(false));
            }}
            className="closeButton"
          >
            <CloseButtonSvg />
          </span>
          <div className="__mainContent">
            {modalTitle()}
            <div className={`__userInfoContainer ${consultant ? 'consultant__userInfoContainer' : ''}`}>
              <Stack direction="vertical">
                <img className="__imgs" src={consultation.user.photo} alt="img" />
                <p>{consultation.user[`name_${userLang}`]}</p>
                {!consultant && <p style={{ color: colors.secondaryGray, marginTop: 0 }}>(you)</p>}
              </Stack>
              <DottedLineSvg />
              <span className="__type">{callModal.type === 'audio' ? <PhoneCallSvg /> : <VideoCallSvg />}</span>
              <Stack direction="vertical">
                <img className="__imgs" src={consultation.consultant.photo} alt="img" />
                <p>{consultation.consultant[`name_${userLang}`] || consultation.consultant.name_en || consultation.consultant.name_ar}</p>
                {consultant && <p style={{ color: colors.secondaryGray, marginTop: 0 }}>(you)</p>}
              </Stack>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <div className="__footer">
          {!consultant && (
            <div className="__description">
              <FocusSvg />
              <p>{consultationPageContent.callModalDescription_v1}</p>
            </div>
          )}

          <IconButton
            className="btnSendConsult"
            onClick={() => handleSetSelectedCall(callModal.type, consultation.id)}
            loading={joinRoomLoading}
            loadingIcon={<BasicButtonSpinner color={colors.white} />}
            iconSide="left"
            content={iconButtonTitle()}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CallModalMobile);
