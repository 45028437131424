import React from 'react'

const MoneySvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
      <path d="M0 0H14V9H0V0ZM7 2.25C7.61884 2.25 8.21233 2.48705 8.64992 2.90901C9.0875 3.33097 9.33333 3.90326 9.33333 4.5C9.33333 5.09674 9.0875 5.66903 8.64992 6.09099C8.21233 6.51295 7.61884 6.75 7 6.75C6.38116 6.75 5.78767 6.51295 5.35008 6.09099C4.9125 5.66903 4.66667 5.09674 4.66667 4.5C4.66667 3.90326 4.9125 3.33097 5.35008 2.90901C5.78767 2.48705 6.38116 2.25 7 2.25ZM3.11111 1.5C3.11111 1.89782 2.94722 2.27936 2.6555 2.56066C2.36378 2.84196 1.96811 3 1.55556 3V6C1.96811 6 2.36378 6.15804 2.6555 6.43934C2.94722 6.72064 3.11111 7.10218 3.11111 7.5H10.8889C10.8889 7.10218 11.0528 6.72064 11.3445 6.43934C11.6362 6.15804 12.0319 6 12.4444 6V3C12.0319 3 11.6362 2.84196 11.3445 2.56066C11.0528 2.27936 10.8889 1.89782 10.8889 1.5H3.11111Z" fill="currentColor" />
    </svg>
  )
}

export default MoneySvg