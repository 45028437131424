import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  iConsultationPayload,
  iConsultation,
  iBookingPayload,
  iStatus,
  IUpdateStatus,
  IActiveChat,
  iConsultationRepliesV2,
  ICancelledChat,
  IViewConsultationChat,
  iJoinRoom,
  iCallLog,
  iScheduleSlots,
  iCouponObj,
} from '../../types/consultantionInterface';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import { iThunkApi, iPaginationMeta, eInboxStatusFilter } from '../../types/commonInterfaces';
import {
  REACT_APP_BASE_API_URL,
  REACT_APP_BASE_API_URL_V2,
  REACT_APP_SENDBIRD_API_TOKEN,
  REACT_APP_SENDBIRD_ID,
} from '../../contants/envConstants';
import { failStatusCodes } from 'utils/Data';
import { getUserDetailsThunk } from 'store/user-slice/user-thunks';
import { getTimeZoneOffset } from 'utils/commonUtils';

export const consultationsThunk = createAsyncThunk<{ id: number; number?: string }, iConsultationPayload, iThunkApi>(
  'consultation/consultation',
  async ({ consultant_id, content, status, photo, file, image, package_id, type, scheduled_date, start, end, offset }, thunkAPI) => {
    const formData = new FormData();
    if (photo) {
      formData.append('photo', photo!);
    }
    if (file) {
      formData.append('file', file!);
    }
    let couponCode = thunkAPI.getState().consultation?.couponCode?.code;
    formData.append('consultant_id', consultant_id?.toString());
    formData.append('content', content);
    formData.append('status', status);
    formData.append('offset', offset);
    formData.append('package_id', package_id?.toString());
    type && formData.append('type', type)
    formData.append('scheduled_date', scheduled_date);
    formData.append('start', start);
    formData.append('end', end);
    couponCode && formData.append('code', couponCode);

    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.CONSULTATION_V2,
      formData,
      'POST',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2,
      true
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    if (resp?.data?.appointment_id) {
      const formData2 = new FormData();
      if (file) {
        formData2.append('file', file!);
      }
      if (image) {
        formData2.append('photo', image);
      }

      formData2.append('content', content);
      formData2.append('id', resp?.data?.appointment_id?.toString());

      const resp1 = await apiManager.request(
        API_ENDPOINTS.GET_CONSULTANT_REPLIES(resp?.data?.appointment_id?.toString()),
        formData2,
        'POST',
        state.user.authToken.access_token,
        REACT_APP_BASE_API_URL,
        true
      );
      if (resp1.data.status === false) {
        return thunkAPI.rejectWithValue(resp.data);
      }
      thunkAPI.dispatch(getUserDetailsThunk());
      return { id: resp?.data?.appointment_id, number: resp?.data?.direct_call_number };
    } else {
      return { id: resp?.data?.appointment_id, number: resp?.data?.direct_call_number };
    }
  }
);

export const getConsultationListThunk = createAsyncThunk<
  { consultations: iConsultation[]; meta: iPaginationMeta },
  { currentPage: number; pageLimit: number; search?: string | null; status?: string | null; order?: string | null, type: eInboxStatusFilter | null },
  iThunkApi
>('consultant/getConsultationList', async ({ currentPage, pageLimit, search = null, status = null, order = 'DESC', type = null }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CONSULTATION_LIST(currentPage, pageLimit, search, status, order, type),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return { consultations: resp.data?.data, meta: resp.data.meta };
});

export const getConsultationRepliesListThunk = createAsyncThunk<
  { consultationsReplies: iConsultationRepliesV2[] },
  { id: number },
  iThunkApi
>('consultant/getConsultationRepliesList', async ({ id }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(API_ENDPOINTS.GET_CONSULTANT_REPLIES(id), {}, 'GET', state.user.authToken.access_token);
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { consultationsReplies: resp.data?.data };
});

export const consultationsRepliesThunk = createAsyncThunk<
  boolean,
  { id: number; content: string; photo?: string | null; file?: Blob | null },
  iThunkApi
>('consultation/consultationReplies', async ({ id, content, photo = null, file = null }, thunkAPI) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file!);
  }
  if (photo) {
    formData.append('photo', photo!);
  }

  formData.append('id', id?.toString());
  formData.append('content', content);

  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CONSULTANT_REPLIES(id),
    formData,
    'POST',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL,
    true
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return true;
});

export const updateConsultationStatusThunk = createAsyncThunk<
  { updateStatus: IUpdateStatus },
  { id: number; status?: string | null; reason?: string | null },
  iThunkApi
>('consultant/updateConsultationStatus', async ({ id, status = null, reason = null }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.UPDATE_CONSULTANT_STATUS,
    {
      id,
      status,
      reason,
    },
    'PUT',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  thunkAPI.dispatch(getUserDetailsThunk());

  return { updateStatus: resp.data };
});

export const deleteConsultationThunk = createAsyncThunk<boolean, { id: number }, iThunkApi>(
  'consultant/deleteConsultationStatus',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.DELETE_CONSULTATION(id),
      {},
      'DELETE',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    return true;
  }
);

export const getZoomLinkThunk = createAsyncThunk<boolean, iBookingPayload, iThunkApi>(
  'user/zoomLink',
  async (zoomPayload: iBookingPayload, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.ZOOM_MEETING,
      zoomPayload,
      'POST',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    return true;
  }
);

export const getConsultationStatusThunk = createAsyncThunk<{ consultationsStatus: iStatus }, {}, iThunkApi>(
  'consultant/getConsultationStatus',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(API_ENDPOINTS.CONSULTATION_STATUS, {}, 'GET', state.user.authToken.access_token);
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { consultationsStatus: resp?.data };
  }
);

export const getSendBirdSessionTokenThunk = createAsyncThunk<{ token: string; expires_at: number }, { userId: string }, iThunkApi>(
  'chat/getSendbirdSessionToken',
  async ({ userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    if (REACT_APP_SENDBIRD_API_TOKEN) {
      const url = `https://api-${REACT_APP_SENDBIRD_ID}.sendbird.com/v3/users/${userId}/token`;
      const resp = await apiManager.request(url, {}, 'POST', '', '', false, REACT_APP_SENDBIRD_API_TOKEN);
      if (resp.data.error) {
        return thunkAPI.rejectWithValue(resp.data.error);
      }
      return resp.data;
    }
  }
);

export const getActiveChatsThunk = createAsyncThunk<{ activChats: IActiveChat }, { id: number }, iThunkApi>(
  'consultant/getActiveChats',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.ACTIVE_CHATS(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { activChats: resp?.data };
  }
);

export const getArchivedChatsThunk = createAsyncThunk<{ consultationsReplies: iConsultationRepliesV2[] }, { id: number }, iThunkApi>(
  'consultant/getArchivedChats',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.ARCHIVED_CHATS(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { consultationsReplies: resp.data?.data };
  }
);

export const getDirectCallLogsThunk = createAsyncThunk<{ log: iCallLog }, { id: number }, iThunkApi>(
  'consultant/getCallLogs',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.GET_CALL_LOGS(id),
      null,
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { log: resp.data };
  }
);

export const getCanceledChatsThunk = createAsyncThunk<{ consultationsReplies: iConsultationRepliesV2[] }, { id: number }, iThunkApi>(
  'consultant/getCancelledChats',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.CANCELLED_CHATS(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    var chat = resp.data as ICancelledChat;
    var replies: iConsultationRepliesV2[] = [
      {
        file: '',
        file_type: '',
        message: chat?.consultant_reason_for_rejection,
        user_id: '',
        user_name: '',
        created_at: chat?.date,
        user_type: '',
        files: [],
      },
      {
        file: '',
        file_type: '',
        message: chat?.user_message,
        user_id: localStorage.getItem('USER_ID')?.toString() || '',
        user_name: '',
        created_at: chat?.date,
        user_type: 'client',
        files: chat?.user_attached_files.length > 0 ? chat?.user_attached_files : [],
      },
    ];

    return { consultationsReplies: replies };
  }
);

export const viewConsultationChatsThunk = createAsyncThunk<{ consultationsReplies: iConsultationRepliesV2[] }, { id: number }, iThunkApi>(
  'consultant/viewConsultationChatsThunk',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.VIEW_CONSULTATION(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    var chat = resp.data as IViewConsultationChat;
    var replies: iConsultationRepliesV2[] = [
      {
        file: '',
        file_type: '',
        message: chat?.message,
        user_id: localStorage.getItem('USER_ID')?.toString() || '',
        user_name: '',
        created_at: chat?.date,
        user_type: 'client',
        files: chat.attached_files?.length > 0 ? [...chat.attached_files] : [],
      },
    ];

    return { consultationsReplies: replies };
  }
);

export const getConsultationDetail = createAsyncThunk<{ consultation: iConsultation }, { id: number }, iThunkApi>(
  'consultant/getConsultationDetail',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.GET_CONSULTATION_DETAIL(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    return { consultation: resp.data.data[0] };
  }
);
// for user as only user can call
export const joinRoomThunk = createAsyncThunk<{ result: iJoinRoom }, { consultation_id: number }, iThunkApi>(
  'consultant/joinRoom',
  async ({ consultation_id }, thunkAPI) => {
    const state = thunkAPI.getState();
    let fD = new FormData();
    fD.append('consultation_id', consultation_id.toString());

    const resp = await apiManager.request(
      API_ENDPOINTS.JOIN_ROOM(consultation_id),
      fD,
      'POST',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );

    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { result: resp.data };
  }
);

export const sendCallToConsultant = createAsyncThunk<{ result: any }, { consultation_id: number; call_type: string }, iThunkApi>(
  'consultant/startVideoCall',
  async ({ consultation_id, call_type }, thunkAPI) => {
    const state = thunkAPI.getState();
    let fD = new FormData();
    fD.append('consultation_id', consultation_id.toString());
    fD.append('userType', 'consultant');
    fD.append('callType', call_type);
    fD.append('roomUrl', '');

    const resp = await apiManager.request(
      API_ENDPOINTS.sendVideoCall(),
      fD,
      'POST',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );

    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { result: resp.data };
  }
);

export const getConsultationAvailableSlotsPerDay = createAsyncThunk<
  { result: iScheduleSlots[] },
  { consultant_id: number, month: number },
  iThunkApi
>('consultant/consultantAvailableSlots', async ({ consultant_id, month }, thunkAPI) => {
  const state = thunkAPI.getState();
  let offset = getTimeZoneOffset();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CNSLT_SLOTS(consultant_id, month, offset),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { result: resp.data.data };
});

export const ChckCouponThunk = createAsyncThunk<
  { result: iCouponObj },
  { code: string, credits: number },
  iThunkApi
>('consultant/checkCoupon', async ({ code, credits }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.CHECK_COUPON(code, credits),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { result: resp.data };
});