import React from 'react'

const UserCircleSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_1888_15403)">
        <mask id="mask0_1888_15403"  maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1888_15403)">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0H16V16H0V0ZM3.66667 11.8767C3.66667 11.99 3.68567 12.1017 3.722 12.207C2.61666 11.086 1.99791 9.57432 2 8C2 4.68633 4.68633 2 8 2C11.3137 2 14 4.68633 14 8C14.0021 9.57678 13.3815 11.0906 12.273 12.212C12.3251 12.0696 12.3441 11.9172 12.3284 11.7663C12.3128 11.6155 12.2629 11.4702 12.1826 11.3416C12.1023 11.2129 11.9937 11.1043 11.8651 11.024C11.7364 10.9436 11.5912 10.8937 11.4403 10.878C8.85433 10.6103 7.16167 10.5847 4.563 10.872C4.31609 10.8998 4.08812 11.0177 3.92273 11.2032C3.75735 11.3886 3.66617 11.6282 3.66667 11.8767ZM1.33333 8C1.33333 11.5907 4.17233 14.5187 7.728 14.6613C7.80326 14.6655 7.87863 14.6671 7.954 14.6663L8 14.6667C11.682 14.6667 14.6667 11.682 14.6667 8C14.6667 4.318 11.682 1.33333 8 1.33333C4.318 1.33333 1.33333 4.318 1.33333 8ZM8 9.33333C8.70724 9.33333 9.38552 9.05238 9.88562 8.55229C10.3857 8.05219 10.6667 7.37391 10.6667 6.66667C10.6667 5.95942 10.3857 5.28115 9.88562 4.78105C9.38552 4.28095 8.70724 4 8 4C7.29276 4 6.61448 4.28095 6.11438 4.78105C5.61428 5.28115 5.33333 5.95942 5.33333 6.66667C5.33333 7.37391 5.61428 8.05219 6.11438 8.55229C6.61448 9.05238 7.29276 9.33333 8 9.33333Z" fill="#1B75BB" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1888_15403">
          <rect width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserCircleSvg