import {iBlog} from '../../types/blogInterface';
import { createSlice } from '@reduxjs/toolkit';
import { getBlogListThunk } from './blog-thunks';

export interface iBlogStoreState {
  blogList: iBlog[];
}

const initialState: iBlogStoreState = {
  blogList: [],
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBlogListThunk.fulfilled, (state, action) => {
      state.blogList = action.payload.blogs.length
        ? action.payload.blogs
        : [];
    });
  },
});

export default blogSlice.reducer;
