import React from 'react';

const VideoCallSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill="currentColor" fillOpacity="0.1" />
      <path
        d="M26.4167 13.584L20.875 17.5423L26.4167 21.5007V13.584Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2917 12H10.5833C9.70888 12 9 12.7089 9 13.5833V21.5C9 22.3745 9.70888 23.0833 10.5833 23.0833H19.2917C20.1661 23.0833 20.875 22.3745 20.875 21.5V13.5833C20.875 12.7089 20.1661 12 19.2917 12Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoCallSvg;
