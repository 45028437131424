import { iSubscriptionPlan } from '../../types/commonInterfaces';
import { createSlice } from '@reduxjs/toolkit';
import { getSubscriptionPlanListThunk } from './subscription-plan-thunks';

export interface iSubscriptionPlanStoreState {
  subscriptionPlanList: iSubscriptionPlan[];
}

const initialState: iSubscriptionPlanStoreState = {
  subscriptionPlanList: [],
};

const subscriptionPlanSlice = createSlice({
  name: 'subscriptionsPlan',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSubscriptionPlanListThunk.fulfilled, (state, action) => {
      state.subscriptionPlanList = action.payload.length ? action.payload : [];
    });
  },
});

export default subscriptionPlanSlice.reducer;
