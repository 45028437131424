import React from 'react';

const NavMsgSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="27" viewBox="0 0 33 27" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2757 1.97765H2.63687C2.27279 1.97765 1.97765 2.27279 1.97765 2.63687V23.6932C1.97765 24.0573 2.27279 24.3524 2.63687 24.3524H30.2757C30.6398 24.3524 30.9349 24.0573 30.9349 23.6932V2.63687C30.9349 2.27279 30.6398 1.97765 30.2757 1.97765ZM2.63687 0C1.18057 0 0 1.18057 0 2.63687V23.6932C0 25.1495 1.18057 26.3301 2.63687 26.3301H30.2757C31.732 26.3301 32.9126 25.1495 32.9126 23.6932V2.63687C32.9126 1.18057 31.732 0 30.2757 0H2.63687Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2948 1.24388C1.68787 0.918708 2.32515 0.918708 2.71822 1.24388L16.5 12.6449L30.2818 1.24388C30.6748 0.918708 31.3121 0.918708 31.7052 1.24388C32.0983 1.56904 32.0983 2.09624 31.7052 2.42141L17.1374 14.4727C16.7676 14.7787 16.2325 14.7787 15.8626 14.4727L1.2948 2.42141C0.901733 2.09624 0.901733 1.56904 1.2948 1.24388Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NavMsgSvg;
