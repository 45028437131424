import { CONSULTANT_AVAILABILITY_STATUS, DEFAULT_LANG, SUPPORTED_LANGS, SUPPORTED_LANGS_ARR } from '../contants/commonConstants';
import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';

import 'dayjs/locale/ar';
import 'dayjs/locale/de';

import { EMAIL_REGEX } from '../contants/regexes';
import { axiosInstance } from '../services/apiManager';
import { IObject, iConsultationPageContent, iConsultentDetailContent } from 'types/contentInterfaces';
import { colors } from './Data';
import { eConsultationModes, eInboxStatusFilter, iSchedule, iScheduleItem, tActionTypes } from 'types/commonInterfaces';

export const checkIfNumber = (str: string) => !isNaN(Number(str.startsWith('+') ? str.slice(1) : str));

export const checkIfValidEmail = (str: string) => EMAIL_REGEX.test(str);

export const checkIfValidEmailOrNumber = (str: string | undefined) => {
  if (str) {
    let isValidEmail = checkIfValidEmail(str);
    let isValidPhone = checkIfNumber(str);
    return isValidPhone || isValidEmail;
  }
  return false;
};

export const detectLangFromBrowser = () => {
  const locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
  const lang = locale.split('-')[0] as SUPPORTED_LANGS;
  return SUPPORTED_LANGS_ARR.includes(lang) ? lang : DEFAULT_LANG;
};

export const setAuhHeaderOnAxios = (value: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${value}`;
};

export const getEllipsePagination = (pageList: number[], activePage: number, visiblePageBtns: number) => {
  if (pageList.length <= visiblePageBtns) {
    return pageList;
  }

  const halfVisiblePageBtns = Math.ceil(visiblePageBtns / 2);
  let ellipsePaginationArray: number[] = [];

  if (activePage <= halfVisiblePageBtns) {
    ellipsePaginationArray = [...ellipsePaginationArray, ...pageList.slice(0, visiblePageBtns - 1)];
  } else if (activePage > halfVisiblePageBtns && activePage + halfVisiblePageBtns - 1 <= pageList.length) {
    ellipsePaginationArray = [
      pageList[0],
      0,
      ...pageList.slice(activePage - halfVisiblePageBtns + 1, activePage),
      ...pageList.slice(activePage, activePage + halfVisiblePageBtns - 2),
    ];
  } else {
    ellipsePaginationArray = [pageList[0], 0, ...pageList.slice(pageList.length - visiblePageBtns + 1, pageList.length)];
  }

  if (activePage + halfVisiblePageBtns - 1 <= pageList.length) {
    if (activePage + halfVisiblePageBtns - 1 < pageList.length) {
      ellipsePaginationArray.push(0);
    }
    ellipsePaginationArray.push(pageList[pageList.length - 1]);
  }
  return ellipsePaginationArray;
};

export const checkFileType = (fileURL: string, type: string): 'audio' | 'other' | 'pdf' => {
  const extension = fileURL.split('.').pop() as string;
  let exts: IObject = {
    webm: 'webm',
    mp4: 'mp4',
    mp3: 'mp3',
    ogg: 'ogg',
    audio: 'audio',
    m4a: 'm4a',
    'com/Attached file for reference': 'com/Attached file for reference',
  };

  if (exts[extension as keyof IObject] || exts[type as keyof IObject] === 'audio') {
    return 'audio';
  } else if (extension === 'pdf') {
    return 'pdf';
  } else {
    return 'other';
  }
};

export const getTimeInSimpleFormatHHMM = (time: string | dayjs.Dayjs) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, 'h:mm');
};

export const getTimeFromNow = (time: string) => {
  dayjs.extend(relativeTime);
  return dayjs(time).fromNow();
};

export const getTimeInAmPm = (time: string) => {
  return dayjs(time).format('h:mm A');
  // return dayjs(time).format('hh:mm A');
};

export const getDateInLocalizedFormatll = (time: string) => {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(advancedFormat);
  return dayjs(time).format('Do MMMM YYYY');
};

export const getDateInLocalizedFormatMMMM = (time: string) => {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(advancedFormat);
  return dayjs(time).format('MMMM D, YYYY	');
};

export const getDateInSimpleFormat = (date?: string) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
};

export const getAppoinmentScheduleMonths = () => {
  dayjs.extend(localeData);
  let allMonths = dayjs.months();
  let currentMonthName = allMonths.at(dayjs().month()) as string;
  let nextMonthName = allMonths.at(dayjs().add(1, 'month').month()) as string;
  let doubleMonthName = allMonths.at(dayjs().add(2, 'month').month()) as string;
  return [currentMonthName, nextMonthName, doubleMonthName];
};

export const monthGeneratorforFuture90Days = (locale: 'en' | 'ar' = 'en') => {
  const currentDate = new Date();
  const monthNumbers: Array<number> = [];
  const monthNames: Array<string> = [];

  for (let i = 0; i < 90; i++) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i);

    const monthNumber = nextDate.getMonth();
    const monthName = nextDate.toLocaleString(locale, { month: 'long' });

    // Add the month number and name to their respective arrays
    if (!monthNumbers.includes(monthNumber)) {
      monthNumbers.push(monthNumber);
      monthNames.push(monthName);
    }

    if (locale === 'ar') {
      monthNumbers.reverse();
    }
  }
  return { monthNumber: monthNumbers, monthNames: monthNames };
};

export const createYoutubeURL = (actualURL: string) => {
  let updatedURL = new URL(actualURL);
  updatedURL.pathname = 'embed/';
  let newURL = updatedURL.host + updatedURL.pathname + updatedURL.searchParams.get('v');
  return 'https://' + newURL;
};

export const getConsultationStatusInAr = (
  status: string,
  lang: string,
  consultationPageContent: iConsultationPageContent,
  type: eConsultationModes
) => {
  let updatedStatus = status?.trim();
  if (lang !== 'ar') {
    let returnStatus =
      updatedStatus === eInboxStatusFilter.pending || updatedStatus === eInboxStatusFilter.closed || updatedStatus === eInboxStatusFilter.canceled
        ? updatedStatus
        : type === eConsultationModes.regular || !type
          ? updatedStatus
          : status === eInboxStatusFilter.active
            ? consultationPageContent.open
            : consultationPageContent.Upcoming;
    return returnStatus;
  } else {
    return updatedStatus === eInboxStatusFilter.pending
      ? consultationPageContent.pending
      : status === eInboxStatusFilter.active
        ? consultationPageContent.open
        : (type === eConsultationModes.scheduled && updatedStatus === eInboxStatusFilter.accepted)
          ? consultationPageContent.Upcoming
          : updatedStatus === eInboxStatusFilter.closed
            ? consultationPageContent.closed
            : updatedStatus === eInboxStatusFilter.canceled
              ? consultationPageContent.cancelled
              : consultationPageContent.open;
  }
};

export const dataLayer = (fnName: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: fnName,
    },
  });
};

export const dataLayerWithParam = (fnName: string, amount: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: fnName,
      amount: amount,
      currency: 'QR',
    },
  });
};

export const getConsultantStatus = (consultDetailPageContent: iConsultentDetailContent, apiStatus: string) => {
  let apiStatusUpdated = apiStatus?.toLowerCase();
  let status = consultDetailPageContent[apiStatusUpdated as keyof iConsultentDetailContent];
  if (
    apiStatusUpdated === CONSULTANT_AVAILABILITY_STATUS.BUSY.toLocaleLowerCase() ||
    apiStatusUpdated === CONSULTANT_AVAILABILITY_STATUS.UNAVAILABLE.toLocaleLowerCase()
  ) {
    return { status, color: colors.secondaryGray };
  } else if (apiStatusUpdated === CONSULTANT_AVAILABILITY_STATUS.ONLINE.toLocaleLowerCase()) {
    return { status, color: colors.primaryLightGreen };
  } else return { status, color: colors.secondaryRed };
};

export const tractFBEventsWithParans = (event: string, data: any) => {
  // @ts-ignore
  if (window?.fbq) {
    // @ts-ignore
    window.fbq && window.fbq('trackCustom', event, ...data);
  }
}

export const tractFBEventsWithOutParans = (event: string) => {
  // @ts-ignore
  if (window?.fbq) {
    // @ts-ignore
    window.fbq && window.fbq('trackCustom', event);
  }
}

export const ScheduleHandler = (
  schedules: iSchedule,
  item: iScheduleItem,
  actionType: tActionTypes,
  active?: '1' | '0',
  removeSlotIndex?: number,
  updateItemSlotIndex?: number,
  updateItemTime?: any
) => {
  let indexToUpdate = schedules.schedule.find((itemDay) => itemDay.day === item.day) as iScheduleItem;
  let updatedSlots = indexToUpdate.slots;
  actionType === 'removeSlot' && removeSlotIndex && indexToUpdate.slots.splice(removeSlotIndex, 1);

  if (actionType === 'updateSlotTime') {
    updatedSlots = indexToUpdate.slots.map((slot, index) => {
      if (index === updateItemSlotIndex) {
        if (updateItemTime) {
          let start = dayjs(getTimeInSimpleFormatHHMM(updateItemTime[0])).format('HH:mm:ss');
          let end = dayjs(getTimeInSimpleFormatHHMM(updateItemTime[1])).format('HH:mm:ss');
          return (slot = { start, end });
        }
      }
      return slot;
    });

    indexToUpdate.slots = updatedSlots;
  }

  let updatedListitem = {
    ...indexToUpdate,
    slots: actionType === 'addSlot' ? [...indexToUpdate.slots, { start: `10:00:00`, end: '12:00:00' }] : [...indexToUpdate.slots],
    active: active || indexToUpdate.active,
  };

  let newList = schedules.schedule.map((scheduleItem) => {
    if (scheduleItem.day === item.day) scheduleItem = updatedListitem;
    return scheduleItem;
  });

  return newList;
};

export const convertQATimeToLocalTimeZone = (timeString: string, locale: SUPPORTED_LANGS = SUPPORTED_LANGS.EN) => {
  let localizedTimeConversionFactor = getTimeZoneOffset();

  let validityOfTime: boolean = false;

  // time conversion
  const [hours, minutes] = timeString.split(':');
  let hours12h = parseInt(hours, 10);

  // Add time factor
  hours12h = hours12h + localizedTimeConversionFactor;

  if (hours12h > -1 && hours12h < 24) {
    validityOfTime = true;
  }

  // Add AM or PM
  let meridian = locale === 'en' ? 'am' : 'am';
  if (hours12h >= 12) {
    meridian = locale === 'en' ? 'pm' : 'pm';
    if (hours12h > 12) {
      hours12h -= 12;
    }
  }
  if (hours12h === 0) {
    hours12h = 12;
  }

  // Generating formatted string
  const timeStringFormattedIn12Hours = `${hours12h.toString().padStart(2, '0')}:${minutes} ${meridian}`;

  return { time: timeStringFormattedIn12Hours, validity: validityOfTime };
};

export const getTimeZoneOffset = () => {
  let date = new Date();
  // let offsetInHours = -(date.getTimeZoneOffset() / 60); // UTC - localtime on computer
  // return offsetInHours - 3;
  let offsetInHours = -(date.getTimezoneOffset() / 60); // UTC - localtime on computer
  return offsetInHours;
};

export const checkIfLangExist = (pathname: string) => {
  if (!pathname.includes('/en/') &&
    !pathname.includes('/ar/') &&
    !pathname.includes('/fr/') &&
    !pathname.includes('/en') &&
    !pathname.includes('/ar') &&
    !pathname.includes('/fr')) {
    return true
  } else {
    return false
  }
}