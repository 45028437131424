import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iAuthToken, iUserDetails, iNotification, iPackages, iTransactions, iActivities } from '../../types/userInterfaces';
import { iPaginationMeta, iReviews } from '../../types/commonInterfaces';
import {
  getUserDetailsThunk,
  loginThunk,
  signupThunk,
  verifyUserOtpThunk,
  resendUserOtpThunk,
  updateUserThunk,
  updatePasswordThunk,
  getNotificationsThunk,
  getUserReviewThunk,
  rechargeUserAccountThunk,
  getPacakgesThunk,
  deleteUserThunk,
  getUserActivitiesThunk,
  socailUserAccountThunk,
  resetPasswordThunk,
  verifyEmailThunk,
  getTransactionHistory
} from './user-thunks';
import { iError } from '../../types/commonInterfaces';

export interface iUserStoreState {
  authToken: iAuthToken;
  userDetails: iUserDetails;
  error: iError;
  success: iError
  emailToVerify: string;
  otpSent: boolean;
  verified: boolean;
  notifications: iNotification[];
  userReviews: iReviews[];
  paginationMeta: iPaginationMeta;
  packages: iPackages[];
  activities: iActivities[];
  transactions: iTransactions[];
  loading: boolean;
}

const DEFAULT_ERROR_MESSAGE = 'Error';

const initialState: iUserStoreState = {
  authToken: {} as iAuthToken,
  userDetails: {} as iUserDetails,
  emailToVerify: '',
  otpSent: false,
  error: {} as iError,
  verified: true,
  notifications: [],
  userReviews: [],
  paginationMeta: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 1,
  },
  packages: [],
  success: {} as iError,
  activities: [],
  transactions: [],
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError(state) {
      state.error = {} as iError;
      state.success = {} as iError;
    },
    clearUserStates(state) {
      state.authToken = {} as iAuthToken;
      state.userDetails = {} as iUserDetails;
      state.emailToVerify = ''
    },
    setAccessToken(state, action: PayloadAction<iAuthToken>) {
      state.authToken = action.payload;
    },
    logoutUser(state) {
      state.userDetails = initialState.userDetails;
      state.authToken = initialState.authToken;
    },
    updateUserAction(state, action) {
      state.userDetails = { ...state.userDetails, ...action.payload, display_name: action.payload.full_name, username: action.payload.full_name }
    }
  },
  extraReducers(builder) {
    builder.addCase(getUserDetailsThunk.fulfilled, (state, action) => {
      state.userDetails = action.payload;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.verified = action.payload?.verified;
      state.emailToVerify = action.payload?.email;
      state.error = {} as iError;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(signupThunk.fulfilled, (state, action) => {
      state.otpSent = true;
      state.emailToVerify = action.payload;
    });
    builder.addCase(signupThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(verifyUserOtpThunk.fulfilled, (state, action) => {
      state.otpSent = false;
      state.verified = true;
      state.authToken = action.payload;
    });
    builder.addCase(verifyUserOtpThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(resendUserOtpThunk.fulfilled, (state, action) => {
      state.otpSent = true;
      state.emailToVerify = action.payload;
    });
    builder.addCase(resendUserOtpThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(updateUserThunk.pending, (state, action) => {
      state.loading = true;
      state.error.status = false;
      state.error.message = '';
    });
    builder.addCase(updateUserThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error.status = false;
      state.success = action?.payload.sucess;
      state.error.message = '';
    });
    builder.addCase(updateUserThunk.rejected, (state, action) => {
      state.loading = false;
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getNotificationsThunk.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications.length
        ? action.payload.notifications
        : [];
    });
    builder.addCase(getNotificationsThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(updatePasswordThunk.fulfilled, (state, action) => {
      state.success = action?.payload.sucess;
    });
    builder.addCase(updatePasswordThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getUserReviewThunk.fulfilled, (state, action) => {
      state.userReviews = action.payload.userReviews.length
        ? action.payload.userReviews
        : [];
      state.paginationMeta = action.payload.meta;
    });
    builder.addCase(getUserReviewThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(rechargeUserAccountThunk.pending, (state, action) => {
      state.loading = true;
      state.error = {} as iError;
    })
    builder.addCase(rechargeUserAccountThunk.fulfilled, (state, action) => {
      state.success = action.payload.sucess;
      state.loading = false;
    });
    builder.addCase(rechargeUserAccountThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
      state.loading = false;
    });
    builder.addCase(getPacakgesThunk.fulfilled, (state, action) => {
      state.packages = action.payload.packages.length
        ? action.payload.packages
        : [];
    });
    builder.addCase(getPacakgesThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(deleteUserThunk.fulfilled, (state, action) => {
      state.success = action.payload.sucess;
    });
    builder.addCase(deleteUserThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getTransactionHistory.fulfilled, (state, action) => {
      state.transactions = action.payload.transactions;
    });
    builder.addCase(getTransactionHistory.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getUserActivitiesThunk.fulfilled, (state, action) => {
      state.activities = action.payload.activities;
    });
    builder.addCase(getUserActivitiesThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(socailUserAccountThunk.fulfilled, (state, action) => {
      state.authToken = action.payload;
    });
    builder.addCase(socailUserAccountThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(resetPasswordThunk.fulfilled, (state, action) => {
      state.success = action.payload.sucess;
    });
    builder.addCase(resetPasswordThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(verifyEmailThunk.fulfilled, (state, action) => {
      state.success = action.payload.sucess;
    });
    builder.addCase(verifyEmailThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
  },
});

export const { clearError, clearUserStates, setAccessToken, logoutUser, updateUserAction } = userSlice.actions;
export default userSlice.reducer;
