import React, { useEffect } from 'react';
import { Stack } from 'react-bootstrap';

import CloseButtonSvg from 'assets/images/svg/CloseButtonSvg';
import { iConsultation } from 'types/consultantionInterface';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { callingOptions } from 'types/commonInterfaces';
import { colors } from 'utils/Data';
import VideoCallSvg from 'assets/images/svg/VideoCallSvg';
import PhoneCallSvg from 'assets/images/svg/PhoneCallSvg';
import DottedLineSvg from 'assets/images/svg/DottedLineSvg';
import FocusSvg from 'assets/images/svg/FocusSvg';
import IconButton from 'layout/Button/IconButton';
import useMediaQuery from 'hooks/useMediaQuery';
import BasicButtonSpinner from 'components/Loaders/BasicButtonSpinner';
import './callingModal.scss';
import { setIsConsultantInMeeting } from 'store/consultation-slice/consultation-slice';

interface iProps {
  type: callingOptions;
  consultation: iConsultation;
  joinRoomLoading: boolean;
  consultant?: boolean;
  requireRelative?: boolean;
  audioRequired?: boolean;
  handleSetSelectedCall: (type: callingOptions, consultation_id: number) => void;
  handleCallingModal: (status: boolean, type: callingOptions) => void;
}

const CallingModal = (props: iProps) => {
  const isDesktop = useMediaQuery('(min-width: 425px)');
  const dispatch = useAppDispatch();
  const { userLang, consultationPageContent } = useAppSelector((state) => state.content);

  const modalTitle = () => {
    if (props.consultant) {
      return (
        <p className="__deep">
          {props.consultation.user[`name_${userLang}`]}{' '}
          {props.type === 'audio'
            ? consultationPageContent.startAudioCallTitle_v1__forConsultant
            : consultationPageContent.startAudioCallTitle_v1__forConsultant}
        </p>
      );
    } else {
      return (
        <p className="__deep">
          {props.type === 'audio' ? consultationPageContent.startAudioCallTitle_v1 : consultationPageContent.startVideoCallTitle_v1}&nbsp;
          {props.consultation.consultant[`name_${userLang}`] ||
            props.consultation.consultant.name_en ||
            props.consultation.consultant.name_ar}
        </p>
      );
    }
  };

  const iconButtonTitle = () => {
    if (props.consultant) {
      return props.type === 'audio'
        ? consultationPageContent.callModalButtonAudio_v1__forConsultant
        : consultationPageContent.callModalButtonVideo_v1__forConsultant;
    } else {
      return props.type === 'audio' ? consultationPageContent.callModalButtonAudio_v1 : consultationPageContent.callModalButtonVideo_v1;
    }
  };

  return isDesktop ? (
    <div className={`callingModalWrapper ${props.requireRelative ? 'consultantCallingModalWrapper' : ''}`}>
      {props.audioRequired && (
        <audio loop autoPlay>
          <source src={require('../../assets/audio/callingRingtone.mp3')} type="audio/mpeg" />
        </audio>
      )}
      <span
        onClick={() => {
          props.handleCallingModal(false, props.type);
          dispatch(setIsConsultantInMeeting(false));
        }}
        className="closeButton"
      >
        <CloseButtonSvg />
      </span>
      <div className="__mainContent">
        {modalTitle()}
        <div className={`__userInfoContainer ${props.consultant ? 'consultant__userInfoContainer' : ''}`}>
          <Stack direction="vertical">
            <img className="__imgs" src={props.consultation.user.photo} alt="img" />
            <p>{props.consultation.user?.[`name_${userLang}`]}</p>
            {!props.consultant && <p style={{ color: colors.secondaryGray, marginTop: 0 }}>(you)</p>}
          </Stack>
          <DottedLineSvg />
          <span className="__type">{props.type === 'audio' ? <PhoneCallSvg /> : <VideoCallSvg />}</span>
          <Stack direction="vertical">
            <img className="__imgs" src={props.consultation.consultant.photo} alt="img" />
            <p>
              {props.consultation.consultant[`name_${userLang}`] ||
                props.consultation.consultant.name_en ||
                props.consultation.consultant.name_ar}
            </p>
            {props.consultant && <p style={{ color: colors.secondaryGray, marginTop: 0 }}>(you)</p>}
          </Stack>
        </div>
      </div>

      <div className="__footer">
        {!props.consultant && (
          <div className="__description">
            <FocusSvg />
            <p>{consultationPageContent.callModalDescription_v1}</p>
          </div>
        )}

        <IconButton
          className="btnSendConsult"
          onClick={() => props.handleSetSelectedCall(props.type, props.consultation.id)}
          loading={props.joinRoomLoading}
          loadingIcon={<BasicButtonSpinner color={colors.white} />}
          iconSide="left"
          content={iconButtonTitle()}
        />
      </div>
    </div>
  ) : null;
};

export default CallingModal;
