import React from 'react'
import BasicSpinner from './Spinner'

interface IProps {
  children: React.ReactElement
}

const Loader = ({ children }: IProps) => {
  return (
    <React.Suspense fallback={<BasicSpinner />}>
      {children}
    </React.Suspense>
  )
}

export default Loader