import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { ROUTE_PATHS } from 'contants/routePaths';
import { getAccessTokenFromLS } from 'utils/storageUtils';
import { useAppSelector } from 'hooks/storeHooks';

const PrivateRoutes: FC = () => {
  const token = getAccessTokenFromLS();
  const userLang = useAppSelector((state) => state.content.userLang);
  let auth = { token: isEmpty(token) ? false : true};  

  return auth.token ? <Outlet /> : <Navigate to={ROUTE_PATHS.LOGIN.replace(':lang', userLang)} />;
};

export default PrivateRoutes;
