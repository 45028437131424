import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { getConsultationPageContentThunk } from 'store/content-slice/content-thunks';
import { callingOptions, iIncomingCallConsultant } from 'types/commonInterfaces';
import { getConsultationDetail, joinRoomThunk } from 'store/consultation-slice/consultation-thunks';
import { iConsultation } from 'types/consultantionInterface';
import CallingModal from './CallingModal';
import useMediaQuery from 'hooks/useMediaQuery';
import CallModalMobile from './CallModalMobile';
import './callingModal.scss'
import { setIsConsultantInMeeting, setSelectedCall } from 'store/consultation-slice/consultation-slice';
import { useSearchParams } from 'react-router-dom';
import { getUserType } from 'utils/storageUtils';
import { REACT_APP_DAILY_CO_CALLING_URL } from 'contants/envConstants';

interface iProps {
  callModal: boolean;
  type: callingOptions | 'audio';
  incomingCallInfo?: iIncomingCallConsultant;
  handleCallingModal: (status: boolean, type: callingOptions) => void;
}

const ConsultantCallModal = ({ callModal, handleCallingModal, type, incomingCallInfo }: iProps) => {
  const userType = getUserType();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [joinRoomLoading, setJoinRoomLoading] = useState(false);
  const [consultation, setConsultation] = useState<iConsultation>();
  const { userLang } = useAppSelector((state) => state.content);
  const { incomingCallConsultationId, activeChats } = useAppSelector(state => state.consultation);
  const authToken = useAppSelector((state) => state.user.authToken);
  const isDesktop = useMediaQuery('(min-width: 425px)');

  useEffect(() => {
    dispatch(getConsultationPageContentThunk());
  }, [userLang])

  useEffect(() => {
    (authToken.access_token && incomingCallInfo?.consultation_id) && getConsultationInfo(incomingCallInfo?.consultation_id);
  }, [userLang, authToken.access_token, incomingCallInfo]);

  const getConsultationInfo = useCallback((id: string) => {
    dispatch(getConsultationDetail({ id: Number(id) }))
      .unwrap()
      .then((res) => setConsultation(res.consultation))
  }, []);


  const otherEvents = (consultation_id: number, type: string) => {
    handleCallingModal(false, 'audio');
    dispatch(setIsConsultantInMeeting(true))
  }

  const handleJoinCall = (consultation_id: number) => {
    if (activeChats?.daily_co_data?.client_meeting_token) {
      const cToken = activeChats.daily_co_data.consultant_meeting_token;
      // let url = `http://localhost:3000/?consultationID=${consultation_id}&userType=${userType.toLocaleLowerCase()}&callType=${type}&roomURL=${activeChats.daily_co_data.room_url}?t=${cToken}`;
      let url = `${REACT_APP_DAILY_CO_CALLING_URL}/?consultationID=${consultation_id}&userType=${userType}&callType=${type}&roomURL=${activeChats.daily_co_data.room_url}?t=${cToken}`;
      dispatch(setSelectedCall({ type: type, clientToken: cToken, roomURL: activeChats.daily_co_data.room_url, url, cnsltId: consultation_id }))
      otherEvents(consultation_id, type)
    } else {
      dispatch(joinRoomThunk({ consultation_id }))
        .unwrap()
        .then((res) => {
          const cToken = res.result.consultant_meeting_token;
          // let url = `http://localhost:3000/?consultationID=${consultation_id}&userType=${userType.toLocaleLowerCase()}&callType=${type}&roomURL=${activeChats.daily_co_data.room_url}?t=${cToken}`;
          let url = `${REACT_APP_DAILY_CO_CALLING_URL}/?consultationID=${consultation_id}&userType=${userType}&callType=${type}&roomURL=${res.result.room_url}?t=${cToken}`;
          dispatch(setSelectedCall({ type: type, clientToken: cToken, roomURL: url, cnsltId: consultation_id }));
          otherEvents(consultation_id, type)
        })
        .catch((err) => {
          handleCallingModal(false, 'audio');
        });
    }
  }

  const handleSetSelectedCall = async (type: callingOptions | undefined, consultation_id: number) => {
    // let path = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    // console.log(path);
    // console.log(searchParams.get('consulting_id'));
    // console.log(incomingCallConsultationId, searchParams.get('consulting_id'));

    if (incomingCallConsultationId === searchParams.get('consulting_id')) {
      // console.log('I will not show 2 modals');
      handleJoinCall(consultation_id)
    } else {
      // pageURLProd
      window.location.href = process.env.NODE_ENV === 'development' ? incomingCallInfo?.pageURLlocal?.toString() || '' : incomingCallInfo?.pageURLProd?.toString() || ''
    }
  };

  return (
    incomingCallInfo ? (
      (isDesktop && consultation) ? (
        <Modal className='consultantCallModal' show={callModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
          <CallingModal
            handleCallingModal={handleCallingModal}
            handleSetSelectedCall={handleSetSelectedCall}
            type={type}
            joinRoomLoading={joinRoomLoading}
            consultation={consultation}
            requireRelative
            consultant
            audioRequired
          />
        </Modal>
      ) : (!isDesktop && consultation) ? (
        <CallModalMobile
          joinRoomLoading={joinRoomLoading}
          callModal={{ status: callModal, type }}
          handleSetSelectedCall={handleSetSelectedCall}
          handleCallingModal={handleCallingModal}
          consultation={consultation}
          consultant
          audioRequired
        />
      ) : null
    ) : null
  )
};

export default ConsultantCallModal;
