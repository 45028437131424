import { createAsyncThunk } from '@reduxjs/toolkit';
import { iThunkApi } from '../../types/commonInterfaces';
import { iBlog } from '../../types/blogInterface';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import { REACT_APP_BASE_API_URL_V2 } from 'contants/envConstants';

export const getBlogListThunk = createAsyncThunk<{ blogs: iBlog[] }, {}, iThunkApi>('blog/getBlogList', // name of slice
  async (_args, thunkAPI) => {
    const state = thunkAPI.getState();
    const blogs = state.blog.blogList
    if (blogs?.length > 0) {
      return { blogs: blogs }
    }
    const resp = await apiManager.request(
      API_ENDPOINTS.GET_BLOG_LIST,
      {},
      'GET',
      state.user.authToken.access_token,
      // REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { blogs: resp.data.data };
  }
);
