import React from 'react'

const RightArrowDeskTop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path d="M5.40366 8.03377C5.3746 8.00474 5.35156 7.97028 5.33583 7.93234C5.3201 7.89441 5.31201 7.85374 5.31201 7.81267C5.31201 7.77161 5.3201 7.73094 5.33583 7.693C5.35156 7.65507 5.3746 7.6206 5.40366 7.59158L7.68296 5.31267L1.56225 5.31267C1.47937 5.31267 1.39989 5.27975 1.34128 5.22114C1.28268 5.16254 1.24975 5.08305 1.24975 5.00017C1.24975 4.91729 1.28268 4.83781 1.34128 4.7792C1.39989 4.7206 1.47937 4.68767 1.56225 4.68767L7.68296 4.68767L5.40366 2.40877C5.34502 2.35013 5.31208 2.2706 5.31208 2.18767C5.31208 2.10475 5.34502 2.02522 5.40366 1.96658C5.4623 1.90794 5.54183 1.875 5.62475 1.875C5.70768 1.875 5.78721 1.90794 5.84585 1.96658L8.65835 4.77908C8.6874 4.8081 8.71045 4.84257 8.72618 4.8805C8.74191 4.91844 8.75 4.95911 8.75 5.00017C8.75 5.04124 8.74191 5.08191 8.72618 5.11984C8.71045 5.15778 8.6874 5.19224 8.65835 5.22127L5.84585 8.03377C5.81682 8.06282 5.78236 8.08587 5.74442 8.1016C5.70649 8.11733 5.66582 8.12542 5.62475 8.12542C5.58369 8.12542 5.54302 8.11733 5.50508 8.1016C5.46715 8.08587 5.43268 8.06282 5.40366 8.03377Z" fill="white" />
    </svg>
  )
}

export default RightArrowDeskTop