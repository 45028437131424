import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom';

interface IReactLink {
  to: string;
  content: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  style?: CSSProperties;
  className?: string;
  iconSide?: string;
}

const ReactLink = (props: IReactLink) => {
  return (
    <Link className={`d-flex  ${props.className ? props.className : ''}`} to={props.to} onClick={props.onClick} style={{ textDecoration: 'none', color: 'inherit', width: '100%', ...props.style }} >
      {props.iconSide === 'left' ? props.icon : null} {props.content} {props.iconSide !== 'left' ? props.icon : null}
    </Link>
  )
}

export default ReactLink