import React from 'react'
import SmallExclamationSvg from 'assets/images/svg/SmallExclamationSvg'

import './noticeComp.scss'

interface iProps {
  content: string;
  className?: string;
}

const ExclamationNotice = ({content, className} : iProps) => {
  return (
    <p className={`exclamationNotice ${className || ''}`}> <SmallExclamationSvg /> {content || ''}</p>
  )
}

export default ExclamationNotice