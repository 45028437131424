import React from 'react'

const SupportSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M8 14.6667L7.83333 12.6667H7.66667C6.08889 12.6667 4.75 12.1167 3.65 11.0167C2.55 9.91671 2 8.57782 2 7.00004C2 5.42226 2.55 4.08337 3.65 2.98337C4.75 1.88337 6.08889 1.33337 7.66667 1.33337C8.45555 1.33337 9.19178 1.48049 9.87533 1.77471C10.5589 2.06893 11.1589 2.47449 11.6753 2.99137C12.1918 3.50826 12.5971 4.10826 12.8913 4.79137C13.1856 5.47448 13.3329 6.21071 13.3333 7.00004C13.3333 7.83337 13.1971 8.63337 12.9247 9.40004C12.6522 10.1667 12.28 10.8778 11.808 11.5334C11.336 12.1889 10.7749 12.7834 10.1247 13.3167C9.47444 13.85 8.76622 14.3 8 14.6667ZM7.65 10.65C7.83889 10.65 8 10.5834 8.13333 10.45C8.26667 10.3167 8.33333 10.1556 8.33333 9.96671C8.33333 9.77782 8.26667 9.61671 8.13333 9.48337C8 9.35004 7.83889 9.28337 7.65 9.28337C7.46111 9.28337 7.3 9.35004 7.16667 9.48337C7.03333 9.61671 6.96667 9.77782 6.96667 9.96671C6.96667 10.1556 7.03333 10.3167 7.16667 10.45C7.3 10.5834 7.46111 10.65 7.65 10.65ZM7.16667 8.53337H8.16667C8.16667 8.20004 8.2 7.96671 8.26667 7.83337C8.33333 7.70004 8.54444 7.4556 8.9 7.10004C9.1 6.90004 9.26667 6.68337 9.4 6.45004C9.53333 6.21671 9.6 5.96671 9.6 5.70004C9.6 5.13337 9.40822 4.70826 9.02467 4.42471C8.64111 4.14115 8.18844 3.9996 7.66667 4.00004C7.17778 4.00004 6.76667 4.13626 6.43333 4.40871C6.1 4.68115 5.86667 5.0116 5.73333 5.40004L6.66667 5.76671C6.72222 5.57782 6.82778 5.3916 6.98333 5.20804C7.13889 5.02448 7.36667 4.93293 7.66667 4.93337C7.96667 4.93337 8.19156 5.01671 8.34133 5.18337C8.49111 5.35004 8.56622 5.53337 8.56667 5.73337C8.56667 5.92226 8.51111 6.09182 8.4 6.24204C8.28889 6.39226 8.15556 6.54493 8 6.70004C7.61111 7.03337 7.37489 7.29737 7.29133 7.49204C7.20778 7.68671 7.16622 8.03382 7.16667 8.53337Z" fill="#1B75BB" />
    </svg>
  )
}

export default SupportSvg