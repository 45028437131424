import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface iBasicButtonSpinner {
  color: string;
}

function BasicButtonSpinner(props: iBasicButtonSpinner) {
  return (
    <Spinner size='sm' animation="border" role="status" style={{ color: props.color }}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default BasicButtonSpinner;