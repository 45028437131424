import React from 'react'

const NavBalanceSvg = () => {
  return (
    <svg
      id="_Group_"
      data-name="&lt;Group&gt;"
      xmlns="http://www.w3.org/2000/svg"
      width="7.891"
      height="14.291"
      viewBox="0 0 7.891 14.291"
    >
      <path
        id="_Path_"
        data-name="&lt;Path&gt;"
        d="M609.165,292.181a1.315,1.315,0,1,0-1.315-1.315A1.315,1.315,0,0,0,609.165,292.181Z"
        transform="translate(-605.219 -289.55)"
        fill="currentColor"
      />
      <circle
        id="_Path_2"
        data-name="&lt;Path&gt;"
        cx="0.996"
        cy="0.996"
        r="0.996"
        transform="translate(2.951 12.3)"
        fill="currentColor"
      />
      <g
        id="_Group_2"
        data-name="&lt;Group&gt;"
        transform="translate(0 3.208)"
      >
        <path
          id="_Path_3"
          data-name="&lt;Path&gt;"
          d="M572.409,344.032q-.111.059-1.068.48c-.078.035-.141.071-.214.1-.073-.034-.135-.07-.214-.1q-.956-.421-1.068-.48a1.619,1.619,0,0,1-.657-1.456,1.76,1.76,0,0,1,.008-.213,1.9,1.9,0,0,1,.023-.2,2.705,2.705,0,0,1,.14-.638.97.97,0,0,1,.194-.331.8.8,0,0,1,.157-.109.842.842,0,0,1,.084-.05c.04-.019.094-.032.141-.048a1.929,1.929,0,0,1,.217-.065c.018,0,.042-.006.06-.009a3.735,3.735,0,0,1,.388-.054h.025q.229-.018.5-.019t.5.019h.026a3.762,3.762,0,0,1,.388.054c.019,0,.042.005.061.009a1.963,1.963,0,0,1,.216.065c.047.016.1.029.142.048a.955.955,0,0,1,.083.05.784.784,0,0,1,.156.109.975.975,0,0,1,.194.331,2.692,2.692,0,0,1,.14.638v.015l1.979-.662c-.008-.076-.015-.153-.028-.223a3.461,3.461,0,0,0-.178-.659.049.049,0,0,0,0-.008,2.107,2.107,0,0,0-.273-.522c-.016-.019-.042-.032-.058-.05a1.74,1.74,0,0,0-.331-.294,1.882,1.882,0,0,0-.151-.135,3.7,3.7,0,0,0-1.381-.4c-.153-.02-.312-.039-.485-.053-.143-.012-.3-.019-.453-.024-.136-.005-.251-.021-.4-.021-.054,0-.1,0-.149,0s-.1,0-.149,0c-.144,0-.259.016-.4.021-.155.005-.307.012-.453.024-.173.014-.331.033-.485.053a3.7,3.7,0,0,0-1.381.4,1.62,1.62,0,0,0-.151.135,1.721,1.721,0,0,0-.331.294c-.016.019-.043.031-.058.05a2.106,2.106,0,0,0-.273.522v.008a3.51,3.51,0,0,0-.177.659c-.021.111-.03.237-.044.358-.017.16-.033.324-.037.5,0,.054-.011.1-.011.153a4.27,4.27,0,0,0,.48,2.315,2.945,2.945,0,0,0,1.341.937,6.984,6.984,0,0,1,1.13.566v.635a.995.995,0,1,0,1.99,0v-.635a6.945,6.945,0,0,1,1.13-.566,3.106,3.106,0,0,0,1.342-.937,4.215,4.215,0,0,0,.478-2.281l-2.067.694A1.393,1.393,0,0,1,572.409,344.032Z"
          transform="translate(-567.18 -339.13)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default NavBalanceSvg