import React from 'react';

const CloseButtonSvg = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="22" height="22" rx="11" fill="white" />
      <rect x="0.5" y="0.5" width="22" height="22" rx="11" stroke="currentColor" />
      <path d="M7 7L11.25 11.25L7 15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.625 15.5L11.375 11.25L15.625 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseButtonSvg;
