import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  iConsultant,
  iPaginationMeta,
  iReviews,
  iThunkApi,
  iError,
  iConsultant_v2,
  iConsultationDetailOfConsultant,
  eConsultationTypes,
} from '../../types/commonInterfaces';
import { iConsultantPayload } from '../../types/consultantInterface';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import { REACT_APP_BASE_API_URL, REACT_APP_BASE_API_URL_V2 } from '../../contants/envConstants';
import { failStatusCodes } from 'utils/Data';

export const getConsultantListThunk = createAsyncThunk<
  { consultants: iConsultant_v2[]; meta: iPaginationMeta },
  { currentPage: number; pageLimit: number; selectedCategory?: number | null; filter?: string | null; order?: string | null },
  iThunkApi
>('consultant/getConsultantList', async ({ currentPage, pageLimit, selectedCategory = null, filter = null, order = null }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CONSULTANT_LIST_V2(currentPage, pageLimit, selectedCategory, filter, order),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  const { data: _, ...obj } = resp.data; // remove obj from resp.data and take other things
  return { consultants: resp.data.data, meta: obj };
});

export const getConsultantThunk = createAsyncThunk<{ consultants: iConsultant_v2 }, { id: number }, iThunkApi>(
  'consultant/getConsultantById',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.GET_CONSULTANT_BY_ID_V2(id),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { consultants: resp.data };
  }
);

export const getConsultantConsultationDetail = createAsyncThunk<
  { consultantDetail: iConsultationDetailOfConsultant[] },
  { id: number; cnsltType: eConsultationTypes },
  iThunkApi
>('consultant/getConsultantConsultationDetail', async ({ id, cnsltType }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_CONSULTATION_DETAIL_BY_CONSULTANT_ID_V2(id, cnsltType),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );  

  if(resp.data.data === 'Unauthenticated.') {
    return thunkAPI.rejectWithValue(resp.data.data);
  }
  
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  
  let updated = resp.data.map((item: iConsultationDetailOfConsultant) => {
    return { ...item, selected: false };
  });
  return { consultantDetail: updated };
});

export const getConsultantDistinguishThunk = createAsyncThunk<{ consultantDistinguish: iConsultant[] }, { id: number }, iThunkApi>(
  'consultant/getDistinguishConsultantById',
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(API_ENDPOINTS.GET_DISTINGUISH_CONSULTANT_BY_ID(id), {}, 'GET', state.user.authToken.access_token);
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { consultantDistinguish: resp.data };
  }
);

export const getConsultantReviewThunk = createAsyncThunk<
  { consultantReviews: iReviews[]; meta: iPaginationMeta },
  { id: number; currentPage: number; pageLimit: number; filter?: string | null; order?: string | null },
  iThunkApi
>('consultant/getReviewsConsultantById', async ({ id, currentPage, pageLimit, filter = null, order = null }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_REVIEWS_CONSULTANT_BY_ID(id, currentPage, pageLimit, filter, order),
    {},
    'GET',
    state.user.authToken.access_token
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return { consultantReviews: resp.data.data, meta: resp.data.meta };
});

export const createConsultantThunk = createAsyncThunk<{ success: iError }, iConsultantPayload, iThunkApi>(
  'user/createConsultant',
  async (payload: iConsultantPayload, thunkAPI) => {
    const state = thunkAPI.getState();
    const formData = new FormData();
    if (payload?.cv_file) {
      formData.append('cv_file', payload?.cv_file);
    }
    if (payload?.degree_file) {
      formData.append('degree_file', payload?.degree_file);
    }
    if (payload?.image) {
      formData.append('image', payload?.image);
    }

    formData.append('name', payload?.name);
    formData.append('degree', payload?.degree);
    formData.append('country', payload?.country);
    formData.append('fb_account', payload?.fb_account);
    formData.append('mobile', payload?.mobile);
    formData.append('email', payload?.email);
    formData.append('categories', payload?.categories.toString());
    formData.append('about', payload?.about);
    formData.append('respons_avg', payload?.respons_avg?.toString());
    formData.append('cost_per_h', payload?.cost_per_h?.toString());
    formData.append('lang', state.content.userLang);

    const resp = await apiManager.request(
      API_ENDPOINTS.CONSULTANTS + state.content.userLang,
      formData,
      'POST',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL,
      true
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { success: resp.data };
  }
);
