import { FC, ReactNode, lazy, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { images } from 'utils/Data';
import { getLoginPageContentThunk } from 'store/content-slice/content-thunks';
import LoaderNull from 'components/Loaders/LoaderNull';
import mobile from '../../assets/images/mobile.png';
import './authLayout.scss';

const WhatsAppLink = lazy(() => import('components/WhatsAppLink'))
const Footer = lazy(() => import('components/core-components/footer/Footer'))
const StoreLink = lazy(() => import('components/store-links/StoreLink'))

const AuthLayout: FC<{ children: ReactNode; card: boolean, mobileImg?: boolean }> = ({
  children,
  card = true,
  mobileImg
}) => {
  const dispatch = useAppDispatch()
  const { loginPageContent, userLang } = useAppSelector((state) => state.content);

  useEffect(() => {
    dispatch(getLoginPageContentThunk())
  }, [userLang])

  return (
    <>
      <div className="loginContainer">
        <Container>
          <Row className='gx-5'>
            <Col lg={6} className="p-5 ps-lg-2 px-lg-5 p-lg-0">
              {card === true ? (
                <>
                  <Card className="loginCard">
                    <Card.Body>{children}</Card.Body>
                  </Card>
                </>
              ) : (
                children
              )}
            </Col>
            <Col lg={6} className="d-none d-lg-block text-center mt-5 align-items-center justify-content-center ps-4 pt-1">
              {mobileImg ? (
                <img
                  className="mw-100"
                  src={mobile}
                  alt="mobile"
                  loading="lazy"
                />
              ) : (
                <picture>
                  <source width={345} height={294} media="(max-width:425px)" srcSet={images.homeImgs.heroImg__mob} />
                  <source width={380} height={323} media="(min-width:425px)" srcSet={images.homeImgs.heroImg} />
                  <img src={images.homeImgs.heroImg__mob} alt="hero-img" className="heroBanner__mainImg" />
                </picture>
              )}
              <div className="app_link pt-2 text-white align-items-center justify-content-center d-flex flex-column">
                {!mobileImg && <h5 className="mb-3 mainContent">
                  {loginPageContent.authLayoutheading || ''}
                </h5>}
                <p className='downloadApp pt-4'>{loginPageContent.downloadTheAppNow}</p>
                <div className="d-flex justify-content-center flex-row-reverse gap-2">
                  <StoreLink />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <LoaderNull>
          <WhatsAppLink />
        </LoaderNull>
      </div>
      <LoaderNull>
        <Footer />
      </LoaderNull>
    </>
  );
};

export default AuthLayout;