import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiManager } from '../../services/apiManager';
import { CONTENT_API_ENDPOINTS } from '../../contants/contentApiEndpoints';
import { REACT_APP_CMS_URL } from '../../contants/envConstants';
import { RootState } from '../index';
import { SUPPORTED_LANGS } from 'contants/commonConstants';

export const getConsultationRequestPageContent = createAsyncThunk(
  'content/request-page-v2',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.REQUEST_PAGE_V2 + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getConsultationRequestReviewsContent = createAsyncThunk(
  'content/request-review-page-v2',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.REQUEST_PAGE_REVIEW_V2 + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getConsultationSuccess = createAsyncThunk(
  'content/payment-success-page-v2',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CONSULTATION_SUCCESS_V2 + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getLoginPageContentThunk = createAsyncThunk(
  'content/loginPage',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.LOGIN + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getSignupPageContentThunk = createAsyncThunk(
  'content/signupPage',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.SIGNUP + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getNavBarContentThunk = createAsyncThunk(
  'content/navbar',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.NAVBAR + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getHomePageContentThunk = createAsyncThunk(
  'content/homePage',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.HOME + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getOtpPageContentThunk = createAsyncThunk(
  'content/otpPage',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.OTP + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getConsultantDetailPageContentThunk = createAsyncThunk(
  'content/ConsultantDetail',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CONSULTANT_DETAIL + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getCategoryDetailPageContentThunk = createAsyncThunk(
  'content/CategoryDetail',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CATEGORY_DETAIL + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data;
  }
);
export const getFaqsContentThunk = createAsyncThunk(
  'content/Faqs',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.FAQS + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data;
  }
);
export const getTestimonialContentThunk = createAsyncThunk(
  'content/Testimonials',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.TESTIMONIAL + SUPPORTED_LANGS.EN,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data;
  }
);
export const getAppInfoPageContentThunk = createAsyncThunk(
  'content/App',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.APP_INFO + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getConsultationPageContentThunk = createAsyncThunk(
  'content/ConultationContent',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CONSULTATIONS + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getTermAndConditionContentThunk = createAsyncThunk(
  'content/TermAndCondition',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.TERMS_AND_CONDITION + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getPrivacyPolicyContentThunk = createAsyncThunk(
  'content/PrivacyPolicy',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.PRIVACY_POLICY + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getJoinAsConsultantContentThunk = createAsyncThunk(
  'content/JoinAsConsultant',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.JOIN_AS_CONSULTANT + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getProfilePageContentThunk = createAsyncThunk(
  'content/ProfilePage',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.PROFILE_PAGE + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getProfilePageContentThunkV2 = createAsyncThunk(
  'content/ProfilePagev2',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.PROFILE_PAGE_V2 + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getAppFaqsContentThunk = createAsyncThunk(
  'content/AppFaqs',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.APP_FAQ + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data;
  }
);
export const getHomeFaqsContentThunk = createAsyncThunk(
  'content/homeFaqs',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.HOME_FAQ + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data;
  }
);
export const getMainFaqContentThunk = createAsyncThunk(
  'content/MainFaq',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.MAIN_FAQ + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getFooterContentThunk = createAsyncThunk(
  'content/Footer',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.FOOTER + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);
export const getMobileContentThunk = createAsyncThunk(
  'content/MobileMarketing',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.MOBILE_MARKETING + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getCustomChatUI_V2 = createAsyncThunk(
  'content/CustomChatUI_V2',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CUSTOM_CHAT_UI_V2 + state.content.userLang,
      {},
      'GET',
      '',
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);

export const getCallLogsPageContentThunk = createAsyncThunk(
  "content/getCallLogsPageContent",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const resp = await apiManager.request(
      CONTENT_API_ENDPOINTS.CALL_LOGS_PAGE_CONTENT + state.content.userLang,
      null,
      "GET",
      "",
      REACT_APP_CMS_URL
    );
    if (resp.data.error) {
      return thunkAPI.rejectWithValue(resp.data.error);
    }
    return resp.data.data.attributes;
  }
);