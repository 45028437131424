import { FC, useState, Dispatch, useEffect, SetStateAction } from 'react';
import Modal from 'react-bootstrap/Modal';
import { iPaymentPayload } from '../../types/userInterfaces';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { getConsultationRequestReviewsContent, getProfilePageContentThunk } from '../../store/content-slice/content-thunks';
import { rechargeUserAccountThunk, getPacakgesThunk } from '../../store/user-slice/user-thunks';
import { dataLayerWithParam, tractFBEventsWithParans } from 'utils/commonUtils';
import { colors, fbEventNames } from 'utils/Data';
import IconButton from 'layout/Button/IconButton';
import BasicButtonSpinner from 'components/Loaders/BasicButtonSpinner';
import BalanceSVGsmall from 'assets/images/svg/BalanceSVGsmall';
import MoneySvg from 'assets/images/svg/MoneySvg';
import ExclamationNotice from 'components/NoticeComps/ExclamationNotice';
import './packageModalStyle.scss';

interface iFiltersProps {
  onHide: Dispatch<boolean>;
  show: boolean;
  sessionFee?: number;
  lowBalance?: boolean;
  setLowBalance?: Dispatch<SetStateAction<boolean>>
}

const PackagesModal: FC<iFiltersProps> = ({ show, onHide, sessionFee, setLowBalance, lowBalance }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userDetails);
  const userLang = useAppSelector((state) => state.content.userLang);
  const content = useAppSelector((state) => state.content.profilePage);
  const reviewContent = useAppSelector((state) => state.content.requestReviewPage_V2);

  const { packages, loading } = useAppSelector((state) => state.user);

  const [payload, setPayload] = useState<iPaymentPayload>({} as iPaymentPayload);

  useEffect(() => {
    dispatch(getProfilePageContentThunk());
    dispatch(getConsultationRequestReviewsContent());
    dispatch(getPacakgesThunk({}));
  }, [userLang, dispatch]);

  const rechargeHandler = () => {
    dataLayerWithParam('begin_checkout', payload.Amount);
    tractFBEventsWithParans(fbEventNames["Initiate checkout"].title, [{
      [fbEventNames['Initiate checkout'].params["Content ID"]]: `${payload.packageId}` || "",
      [fbEventNames['Initiate checkout'].params["Content Type"]]: payload.CustomerName,
      [fbEventNames['Initiate checkout'].params["Currency"]]: 'QAR',
      [fbEventNames['Initiate checkout'].params["valueToSum"]]: `${payload.Amount}`
    }])
    dispatch(
      rechargeUserAccountThunk({
        ...payload,
        CustomerEmail: user.email,
        CustomerName: user?.display_name || user?.username,
        CustomerMobile: user.phone,
      })
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide(!show);
        setLowBalance && setLowBalance(false);
        localStorage.removeItem('showPackage');
      }}
      size="lg"
      centered
      className="rechargeModal"
    >
      <Modal.Header closeButton>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <Modal.Title>{content?.walletRecharge || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lowBalance ? (
          <>
            <div className="d-flex gap-3 justify-content-center align-items-center lowBalanceWrapper">
              <div className="d-flex flex-column align-items-center sessionFee">
                <h4 className="sessionFees">{reviewContent.sessionFeesText}</h4>
                <p><BalanceSVGsmall /> {sessionFee} {content.credits}</p>
              </div>

              <div className="d-flex flex-column align-items-center userBalance">
                <h4 className="sessionFees">{reviewContent.yourBalanceText}</h4>
                <p><BalanceSVGsmall /> {user.user_balance} {content.credits}</p>
              </div>
            </div>
            <div className="notice">
              <ExclamationNotice className="justify-content-center" content={reviewContent.youHaveInsufficientBalance || ''} />
            </div>
          </>
        ) : null}
        <div className="packageList">
          {packages?.length > 0 &&
            packages?.map((item) => (
              <div className="listItem" key={item?.package_id}>
                <input
                  type="radio"
                  name="amount"
                  id={item?.package_id?.toString()}
                  checked={payload?.packageId === item?.package_id}
                  onChange={() =>
                    setPayload({
                      ...payload,
                      Amount: item.amount,
                      packageId: item?.package_id,
                    })
                  }
                />
                <label htmlFor={item?.package_id?.toString()}>
                  <div className="pointsHolder" >
                    <p className="d-flex title align-items-center">
                      <BalanceSVGsmall color={colors.primaryBlue} /> {item?.new_amount}
                    </p>
                    <p className="credits">
                      {content?.credits || ''}
                    </p>

                    <p className="walletRecharge">
                      {content?.walletRecharge || ''}
                    </p>

                    <p className="cashInfo d-flex align-items-center">
                      <MoneySvg /> QAR {item.name}
                    </p>
                  </div>
                </label>
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 py-4">
        <ExclamationNotice content={content?.youWalletWillbeToppedUp} />

        <IconButton
          content={content.packagesbtn}
          className={`px-5 iconButtonPackageModal`}
          loading={loading}
          disabled={loading || !Boolean(payload.packageId)}
          loadingIcon={<BasicButtonSpinner color={colors.white} />}
          onClick={() => rechargeHandler()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default PackagesModal;
