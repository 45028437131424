import { iCategory } from '../../types/commonInterfaces';
import { createSlice } from '@reduxjs/toolkit';
import { getCategoryListThunk } from './category-thunks';

export interface iCategoryStoreState {
  categoryList: iCategory[];
}

const initialState: iCategoryStoreState = {
  categoryList: [],
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCategoryListThunk.fulfilled, (state, action) => {
      state.categoryList = action.payload.length ? action.payload : [];
    });
  },
});

export default categorySlice.reducer;
