import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { getUserType, removeAccessTokenFromLS } from 'utils/storageUtils';
import { NavDropdown } from 'react-bootstrap';
import { colors, fbEventNames } from 'utils/Data';
import { eUserType } from 'types/userInterfaces';
import { logoutThunk } from 'store/user-slice/user-thunks';
import { tractFBEventsWithOutParans } from 'utils/commonUtils';
import UserCircleSvg from 'assets/images/navImgs/UserCircleSvg';
import PackagesModal from '../../modals/PackagesModal';
import NotificationBellSvg from 'assets/images/navImgs/NotificationBellSvg';
import SupportSvg from 'assets/images/navImgs/SupportSvg';
import LogoutSvg from 'assets/images/navImgs/LogoutSvg';
import RiArrowDownSLineSvg from 'assets/images/navImgs/RiArrowDownSLineSvg';
import NavBalanceSvg from 'assets/images/svg/NavBalanceSvg';
import useMediaQuery from 'hooks/useMediaQuery';
import NavMsgSvg from 'assets/images/navImgs/NavMsgSvg';
import './NavBar.scss'

const UserMenuLinks = () => {
  const userType = getUserType().toLowerCase();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useAppSelector((state) => state.user.userDetails)
  const user_balance = useAppSelector((state) => state?.user?.userDetails?.user_balance || 0);
  const number_notifications = useAppSelector((state) => state?.user?.userDetails?.unread_notifications);
  const unreadMessaged = useAppSelector((state) => state?.user?.userDetails?.incoming_messages_notification);
  const { userLang, navBarContent } = useAppSelector((state) => state.content);
  const [rechargeShow, setRechargesetShow] = useState<boolean>(location?.search === '?showPackages=true');
  const [notificationMsg, setNotificationMsg] = useState<string>('0');
  const [notification, setNotification] = useState<string>('0');
  const isDesktop = useMediaQuery('(min-width: 991px)');

  let userColorClassName = location.pathname === `/${userLang}` || location.pathname === `/${userLang}/` || location.pathname === '/' ? 'sidebar__blue' : '';

  const logoutHandle = () => {
    removeAccessTokenFromLS()
    dispatch(logoutThunk());
    window.location.href = `/${userLang}/logout`;
  };

  useEffect(() => {
    if (number_notifications) {
      setNotification(number_notifications);
    }
    if (unreadMessaged) {
      setNotificationMsg(unreadMessaged?.toString());
    }
    if (location?.search === '?showPackages=true') {
      userType.toLocaleLowerCase() !== eUserType.consultant && setRechargesetShow(true);
    }
    if (
      !isEmpty(localStorage?.showPackage) &&
      Number(localStorage?.showPackage > user_balance)
    ) {
      userType.toLocaleLowerCase() !== eUserType.consultant && setRechargesetShow(true);
    }
  }, [number_notifications, unreadMessaged, location?.search, localStorage]);

  const notificationHandler = (val: string) => {
    if (val === 'messages') {
      setNotificationMsg('0');
    }
    if (val === 'notification') {
      setNotification('0');
    }
  };

  const renderTitle = () => {
    return (
      <div className='nav__userInfoWrapper'>
        {userDetails?.avatar ? (
          <img src={userDetails.avatar} alt='avatar' />
        ) : null}
        <span className={userColorClassName}>{userDetails?.display_name || userDetails?.username}</span>
        <RiArrowDownSLineSvg />
      </div>
    )
  }

  const navUserLinks = (value: string) => navigate(`/${userLang}/${value}`)

  return (
    <>
      {!isDesktop && (
        <div className='navbar__mobile'>
          <Link
            className="menuLink"
            to={`${userLang}/chat`}
            title="Consultation"
            onClick={() => {
              notificationHandler('messages');
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="Path_62717"
                data-name="Path 62717"
                d="M20,4H4V16H18.83L20,17.17V4m0-2a2,2,0,0,1,1.99,2L22,22l-4-4H4a2.006,2.006,0,0,1-2-2V4A2.006,2.006,0,0,1,4,2ZM18,12H10v2h8Zm0-3H6v2H18Zm0-3H6V8H18Z"
                transform="translate(-2 -2)"
                fill="currentColor"
              />
            </svg>
            <span className="countNotification">{notificationMsg}</span>
          </Link>
          <Link
            className="menuLink"
            to={`${userLang}/notification`}
            title="Notification"
            onClick={() => {
              notificationHandler('notification');
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="19.5"
              viewBox="0 0 16 19.5"
            >
              <path
                id="Path_62707"
                data-name="Path 62707"
                d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5Z"
                transform="translate(-4 -2.5)"
                fill="currentColor"
              />
            </svg>
            <span className="countNotification">{notification}</span>
          </Link>
          <Link className="menuLink" to={`${userLang}/profile`} title="Profile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="Path_62721"
                data-name="Path 62721"
                d="M10.25,13A1.25,1.25,0,1,1,9,11.75,1.25,1.25,0,0,1,10.25,13ZM15,11.75A1.25,1.25,0,1,0,16.25,13,1.25,1.25,0,0,0,15,11.75ZM22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12ZM10.66,4.12A7.99,7.99,0,0,0,17.5,8a8.346,8.346,0,0,0,1.34-.12A7.99,7.99,0,0,0,12,4,8.346,8.346,0,0,0,10.66,4.12ZM4.42,9.47A8.046,8.046,0,0,0,8.08,5.03,8.046,8.046,0,0,0,4.42,9.47ZM20,12a7.88,7.88,0,0,0-.33-2.24A10.306,10.306,0,0,1,17.5,10,10,10,0,0,1,9.74,6.31,10.016,10.016,0,0,1,4,11.86.653.653,0,0,1,4,12a8,8,0,0,0,16,0Z"
                transform="translate(-2 -2)"
                fill="currentColor"
              />
            </svg>
          </Link>
          <span
            className="menuLink balance__link__mob"
            onClick={() => {              
              userType === eUserType.client && setRechargesetShow(true);
            }}
            title="Balance"
          >
            <span className="pe-1">{user_balance ? user_balance : 0}</span>
            <svg
              id="_Group_"
              data-name="&lt;Group&gt;"
              xmlns="http://www.w3.org/2000/svg"
              width="7.891"
              height="14.291"
              viewBox="0 0 7.891 14.291"
            >
              <path
                id="_Path_"
                data-name="&lt;Path&gt;"
                d="M609.165,292.181a1.315,1.315,0,1,0-1.315-1.315A1.315,1.315,0,0,0,609.165,292.181Z"
                transform="translate(-605.219 -289.55)"
                fill="currentColor"
              />
              <circle
                id="_Path_2"
                data-name="&lt;Path&gt;"
                cx="0.996"
                cy="0.996"
                r="0.996"
                transform="translate(2.951 12.3)"
                fill="currentColor"
              />
              <g
                id="_Group_2"
                data-name="&lt;Group&gt;"
                transform="translate(0 3.208)"
              >
                <path
                  id="_Path_3"
                  data-name="&lt;Path&gt;"
                  d="M572.409,344.032q-.111.059-1.068.48c-.078.035-.141.071-.214.1-.073-.034-.135-.07-.214-.1q-.956-.421-1.068-.48a1.619,1.619,0,0,1-.657-1.456,1.76,1.76,0,0,1,.008-.213,1.9,1.9,0,0,1,.023-.2,2.705,2.705,0,0,1,.14-.638.97.97,0,0,1,.194-.331.8.8,0,0,1,.157-.109.842.842,0,0,1,.084-.05c.04-.019.094-.032.141-.048a1.929,1.929,0,0,1,.217-.065c.018,0,.042-.006.06-.009a3.735,3.735,0,0,1,.388-.054h.025q.229-.018.5-.019t.5.019h.026a3.762,3.762,0,0,1,.388.054c.019,0,.042.005.061.009a1.963,1.963,0,0,1,.216.065c.047.016.1.029.142.048a.955.955,0,0,1,.083.05.784.784,0,0,1,.156.109.975.975,0,0,1,.194.331,2.692,2.692,0,0,1,.14.638v.015l1.979-.662c-.008-.076-.015-.153-.028-.223a3.461,3.461,0,0,0-.178-.659.049.049,0,0,0,0-.008,2.107,2.107,0,0,0-.273-.522c-.016-.019-.042-.032-.058-.05a1.74,1.74,0,0,0-.331-.294,1.882,1.882,0,0,0-.151-.135,3.7,3.7,0,0,0-1.381-.4c-.153-.02-.312-.039-.485-.053-.143-.012-.3-.019-.453-.024-.136-.005-.251-.021-.4-.021-.054,0-.1,0-.149,0s-.1,0-.149,0c-.144,0-.259.016-.4.021-.155.005-.307.012-.453.024-.173.014-.331.033-.485.053a3.7,3.7,0,0,0-1.381.4,1.62,1.62,0,0,0-.151.135,1.721,1.721,0,0,0-.331.294c-.016.019-.043.031-.058.05a2.106,2.106,0,0,0-.273.522v.008a3.51,3.51,0,0,0-.177.659c-.021.111-.03.237-.044.358-.017.16-.033.324-.037.5,0,.054-.011.1-.011.153a4.27,4.27,0,0,0,.48,2.315,2.945,2.945,0,0,0,1.341.937,6.984,6.984,0,0,1,1.13.566v.635a.995.995,0,1,0,1.99,0v-.635a6.945,6.945,0,0,1,1.13-.566,3.106,3.106,0,0,0,1.342-.937,4.215,4.215,0,0,0,.478-2.281l-2.067.694A1.393,1.393,0,0,1,572.409,344.032Z"
                  transform="translate(-567.18 -339.13)"
                  fill="currentColor"
                />
              </g>
            </svg>
          </span>
          <span className="menuLink" onClick={logoutHandle} title="Logout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 512 512"
            >
              <path
                d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
              />
            </svg>
          </span>
        </div>
      )}

      {isDesktop &&
        <>
          <NavDropdown
            title={renderTitle()}
            id="lang-nav-dropdown"
            className={`userInfoDropdown ${userColorClassName} lang-nav-dropdown__userLink`}
          >
            <NavDropdown.Item id='myprofile' onClick={() => navUserLinks(`profile`)}>
              <UserCircleSvg />
              <span>{navBarContent.navButton.myProfile}</span>
            </NavDropdown.Item>
            <NavDropdown.Item id="notification" onClick={() => navUserLinks('notification')}>
              <NotificationBellSvg />
              <span>{navBarContent.navButton.notifications}</span>
              <span className='unreadNotfs'>{unreadMessaged}</span>
            </NavDropdown.Item>

            <NavDropdown.Item id="suppport" onClick={() => {
              tractFBEventsWithOutParans(fbEventNames.Contact.title);
              window.location.href = 'https://wa.me/97450002816'
            }}>
              <SupportSvg />
              <span>{navBarContent.navButton.support}</span>
            </NavDropdown.Item>
            <NavDropdown.Item id="logout" onClick={logoutHandle}>
              <LogoutSvg />
              <span style={{ color: colors.secondaryRed }}>{navBarContent.navButton.logout}</span>
            </NavDropdown.Item>
          </NavDropdown>
          <span
            className="inbox__link balance__link"
            onClick={() => {
              navigate(`/${userLang}/chat`)
            }}
            title='inbox'
          >
            <NavMsgSvg />
          </span>
          <span
            className="balance__link"
            onClick={() => {
              userType === eUserType.client &&  setRechargesetShow(true);
            }}
            title="Balance"
          >
            <NavBalanceSvg />
            <span>{user_balance ? user_balance : 0}</span>
          </span>
        </>
      }
      <PackagesModal show={rechargeShow} onHide={setRechargesetShow} />
    </>
  );
};

export default UserMenuLinks;
