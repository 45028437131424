import React from 'react'

const RiArrowDownSLineSvg = () => {
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.976563 1.05989C1.16754 0.868923 1.41059 0.773437 1.70573 0.773437C2.00087 0.773437 2.24392 0.868923 2.4349 1.0599L6.4974 5.1224L10.5599 1.0599C10.7509 0.868923 10.9939 0.773437 11.2891 0.773437C11.5842 0.773437 11.8273 0.868923 12.0182 1.0599C12.2092 1.25087 12.3047 1.49392 12.3047 1.78906C12.3047 2.0842 12.2092 2.32726 12.0182 2.51823L7.22656 7.3099C7.1224 7.41406 7.00955 7.48802 6.88802 7.53177C6.76649 7.57552 6.63629 7.59705 6.4974 7.59635C6.35851 7.59635 6.2283 7.57448 6.10677 7.53073C5.98524 7.48698 5.8724 7.41337 5.76823 7.3099L0.976563 2.51823C0.785592 2.32726 0.690104 2.0842 0.690104 1.78906C0.690104 1.49392 0.785592 1.25087 0.976563 1.05989Z" fill="#6D6E70" />
    </svg>

  )
}

export default RiArrowDownSLineSvg