import React from 'react'

const NotificationBellSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_1888_15421)">
        <path d="M9.9999 12.6667C10 13.0031 9.87296 13.3271 9.64423 13.5737C9.41551 13.8204 9.102 13.9715 8.76657 13.9967L8.66657 14H7.33323C6.99685 14.0001 6.67285 13.8731 6.4262 13.6444C6.17955 13.4156 6.02846 13.1021 6.00323 12.7667L5.9999 12.6667H9.9999ZM7.9999 1.33337C9.20989 1.33335 10.3726 1.8033 11.2427 2.64409C12.1129 3.48488 12.6224 4.63077 12.6639 5.84004L12.6666 6.00004V8.50937L13.8812 10.9387C13.9342 11.0447 13.9608 11.162 13.9585 11.2805C13.9563 11.399 13.9254 11.5152 13.8685 11.6191C13.8115 11.723 13.7302 11.8116 13.6316 11.8773C13.5329 11.9429 13.4198 11.9837 13.3019 11.996L13.2252 12H2.77457C2.65601 12.0001 2.53921 11.9714 2.43418 11.9164C2.32915 11.8614 2.23902 11.7817 2.17151 11.6843C2.104 11.5868 2.06113 11.4744 2.04657 11.3568C2.03201 11.2391 2.04619 11.1197 2.0879 11.0087L2.11857 10.9387L3.33323 8.50937V6.00004C3.33323 4.76236 3.8249 3.57538 4.70007 2.70021C5.57524 1.82504 6.76222 1.33337 7.9999 1.33337Z" fill="#1B75BB" />
      </g>
      <defs>
        <clipPath id="clip0_1888_15421">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotificationBellSvg