import { createAsyncThunk } from '@reduxjs/toolkit';
import { iSubscriptionPlan, iThunkApi } from '../../types/commonInterfaces';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';

export const getSubscriptionPlanListThunk = createAsyncThunk<
  iSubscriptionPlan[],
  undefined,
  iThunkApi
>('subscriptionPlan/getSubscriptionPlanList', async (arg, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_SUBSCRIPTION_PLANS,
    {},
    'GET',
    state.user.authToken.access_token
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return resp.data.data;
});
