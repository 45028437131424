import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function BasicSpinner() {
  return (
    <div style={{ height: '100vh' }} className='d-flex justify-content-center align-items-center'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default BasicSpinner;