import React from 'react'

const BackArrowDeskTop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18 16" fill="none">
      <path d="M8.03122 15.281C8.10095 15.2114 8.15627 15.1287 8.19401 15.0376C8.23175 14.9466 8.25118 14.849 8.25118 14.7504C8.25118 14.6519 8.23175 14.5543 8.19401 14.4632C8.15627 14.3722 8.10095 14.2894 8.03122 14.2198L2.5609 8.75042L17.2506 8.75042C17.4495 8.75042 17.6403 8.6714 17.7809 8.53075C17.9216 8.3901 18.0006 8.19933 18.0006 8.00042C18.0006 7.8015 17.9216 7.61074 17.7809 7.47009C17.6403 7.32943 17.4495 7.25042 17.2506 7.25042L2.5609 7.25042L8.03122 1.78104C8.17195 1.64031 8.25101 1.44944 8.25101 1.25042C8.25101 1.05139 8.17195 0.860523 8.03122 0.719792C7.89048 0.579062 7.69961 0.5 7.50059 0.5C7.30157 0.5 7.1107 0.579062 6.96996 0.719792L0.219965 7.46979C0.150232 7.53945 0.0949131 7.62216 0.0571699 7.71321C0.0194267 7.80426 -3.23545e-07 7.90185 -3.27854e-07 8.00042C-3.32162e-07 8.09898 0.0194267 8.19657 0.0571699 8.28762C0.0949131 8.37867 0.150232 8.46139 0.219965 8.53104L6.96996 15.281C7.03962 15.3508 7.12234 15.4061 7.21338 15.4438C7.30443 15.4816 7.40203 15.501 7.50059 15.501C7.59915 15.501 7.69675 15.4816 7.78779 15.4438C7.87884 15.4061 7.96156 15.3508 8.03122 15.281Z" fill="#1B75BB" />
    </svg>
  )
}

export default BackArrowDeskTop