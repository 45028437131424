import React from 'react';
import { Button } from 'react-bootstrap';

interface iIconButton {
  className: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
  loadingIcon?: React.ReactElement;
  content: string;
  iconSide?: string;
  style?: React.CSSProperties;
}

const IconButtonRightSide = (props: iIconButton) => {

  return (
    <Button
      style={props.style ? props.style : {}}
      disabled={props.disabled}
      onClick={props.onClick}
      className={`reusableIconButton d-flex justificy-content-center align-items-center gap-2 ${props.className ? props.className : ''}`}
      variant="primary"
    >
      {props.content} {props.loading ? props.loadingIcon : props.icon}
    </Button>
  );
};

export default IconButtonRightSide;
