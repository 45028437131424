import { SUPPORTED_LANGS } from "contants/commonConstants";

export interface iUserDetails {
  total_consultations: number | 0;
  id: number;
  username: string;
  nicename: string;
  displayname: string;
  pending_consultations: number;
  display_name: string;
  email: string;
  firstname: string;
  lastname: string;
  user_type: string;
  status: string;
  avatar: string;
  locale: SUPPORTED_LANGS;
  user_balance?: number | 0;
  consultor_id: string;
  marital_status: string;
  gender: string;
  country: string;
  phone: string;
  user_birthday: string;
  user_age: number;
  referral_code: string;
  full_name_function: string;
  verified: boolean;
  number_notifications: number;
  number_ConNotifications: number;
  all_notification: number;
  Promotions_notification: number;
  consultant_available_notification: number;
  incoming_messages_notification: number;
  login_type: string;
  all_notifications: string;
  unread_notifications: string;
  total_scheduled_inapp_consultations: string;
}
export interface iLoginPayload {
  username: string;
  password: string;
  fcm_web_token?: string;
}

export interface iLogout {
  status: boolean,
  user_id: string;
  fcm_user_token: string;
}

export interface iTransactions {
  date: string;
  transactions: {
    id: number;
    date: string;
    price: number;
    time: string;
    title: string;
    transid: string | null;
    tt: string;
    type: string;
    in_app_purchase: number;
  }[]
}

export interface iSignupPayload {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  userImage: string;
}

export interface iUpadteNamePayload {
  id: number;
  full_name: string;
  name: string;
  email: string;
  gender: string;
  phone_number: number;
  password: string;
  fa_user_status: string;
  locale: string;
  date_of_birth: string;
}

export interface iUpadatePasswordPayload {
  id: number;
  old_password: string;
  password_confirmation: string;
  password: string
}

export interface iNotification {
  id: number;
  user_id: string;
  consulting_id: number;
  message: string;
  created_at: string;
  consultation_status: string;
  title: String;
}
export interface iAuthToken {
  access_token: string;
  expires_in: string;
  token_type: 'Bearer';
  verified: boolean;
  email: string;
  locale: SUPPORTED_LANGS;
}
export interface iPaymentPayload {
  reference: string;
  Description: string;
  CustomerEmail: string;
  CustomerName: string;
  CustomerMobile: string;
  Amount: number;
  access_token: string;
  packageId: number;
}
export interface iPackages {
  package_id: number;
  currency: string;
  code: string;
  price: string;
  plan_cost: number;
  amount: number;
  amount_dollar: number;
  color_code: string;
  discount: number;
  in_app_purchase: string;
  name: string;
  qar_amount_after_discount: number;
  new_amount: number;
}
export interface iTransactions {
  date: string;
  transactions: {
    id: number;
    date: string;
    price: number;
    time: string;
    title: string;
    transid: string | null;
    tt: string;
    type: string;
    in_app_purchase: number;
  }[]
}

export interface iActivities {
  id: number;
  date: string;
  price: number;
  time: string;
  title: string;
  transid: string;
  tt: string;
  type: string;
  in_app_purchase: number;
}

export interface iSocialLoginPayload {
  access_token: string;
  email: string;
  platform: string;
  social_username: string;
  fcm_web_token?: string;
}

export enum eUserType {
  Consultant = 'Consultant',
  User = 'User',
  Client = 'Client',
  client = 'client',
  consultant = 'consultant'
}
