import React from 'react'

const DottedLineSvg = () => {
  return (
    <svg className='dottedLineSvg' width="90" height="1" viewBox="0 0 90 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.5" x2="90" y2="0.5" stroke="#00ADEE" strokeDasharray="2 2" />
    </svg>

  )
}

export default DottedLineSvg