import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserType, setUserId, removeAccessTokenFromLS } from '../../utils/storageUtils';
import {
  iAuthToken,
  iLoginPayload,
  iSignupPayload,
  iUserDetails,
  iUpadteNamePayload,
  iUpadatePasswordPayload,
  iNotification,
  iPaymentPayload,
  iPackages,
  iSocialLoginPayload,
  iLogout,
  iTransactions,
  iActivities
} from '../../types/userInterfaces';

import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import {
  LOGIN_AUTH_CLIENT_ID,
  LOGIN_AUTH_KEY,
  REACT_APP_BASE_API_URL_V2,
} from '../../contants/envConstants';
import {
  iThunkApi,
  iPaginationMeta,
  iReviews,
  iError,
  iSchedule
} from '../../types/commonInterfaces';
import { failStatusCodes } from 'utils/Data';
import { clearUserStates } from './user-slice';
import { ROUTE_PATHS } from 'contants/routePaths';
import { getTimeZoneOffset } from 'utils/commonUtils';

export const loginThunk = createAsyncThunk<
  iAuthToken,
  iLoginPayload,
  iThunkApi
>('user/login', async (loginPayload: iLoginPayload, thunkAPI) => {
  const state = thunkAPI.getState();
  //state.user.emailToVerify=loginPayload.username;
  const resp = await apiManager.request(
    API_ENDPOINTS.LOGIN,
    {
      ...loginPayload,
      client_id: LOGIN_AUTH_CLIENT_ID,
      client_secret: LOGIN_AUTH_KEY,
      lang: state.content.userLang,
    },
    'POST'
  );
  if (resp.data.error) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  const res = { ...resp.data, email: loginPayload.username }
  return res;
});

export const logoutThunk = createAsyncThunk<
  any,
  undefined,
  iThunkApi
>('user/logout', async (args, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.logout(),
    '',
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.error) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return resp;
});

export const getTransactionHistory = createAsyncThunk<
  { transactions: iTransactions[] },
  {},
  iThunkApi
>('user/getTransactionHistory',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.TRANCTIONAL_HISTORY_V2 + '?lang=' + state.content.userLang,
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { transactions: resp.data };
  }
);

export const signupThunk = createAsyncThunk<
  string,
  iSignupPayload,
  { rejectValue: { messages: string; status: boolean } } & iThunkApi
>('user/signup', async ({ name, email, phoneNumber, password }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.SIGNUP,
    {
      email,
      password,
      phone_number: phoneNumber,
      full_name: name,
      lang: state.content.userLang,
    },
    'POST',
    // '',
    // REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return email;
});
export const verifyUserOtpThunk = createAsyncThunk<
  iAuthToken,
  string,
  iThunkApi
>('user/verifyOTP', async (code, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.VERIFY_OTP,
    {
      code,
      email: state.user.emailToVerify,
      lang: state.content.userLang,
    },
    'POST',
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return resp.data;
});
export const getUserDetailsThunk = createAsyncThunk<
  iUserDetails,
  undefined,
  iThunkApi
>('user/getDetails', async (arg, thunkAPI) => {

  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_USER_PROFILE,
    {},
    'GET',
    state.user.authToken.access_token
  );

  if (resp.data?.data === "Unauthenticated.") {
    removeAccessTokenFromLS();
    thunkAPI.dispatch(clearUserStates());
    window.location.href = ROUTE_PATHS.LOGIN.replace(':lang', thunkAPI.getState().content.userLang)
  }

  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  setUserType(resp?.data?.user?.user_type);
  setUserId(resp?.data?.user?.id?.toString());
  return resp.data.user;
});

export const resendUserOtpThunk = createAsyncThunk<
  string,
  {},
  iThunkApi
>('user/resendOTP', async (args, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.RESEND_OTP,
    {
      email: state.user.emailToVerify,
      lang: state.content.userLang,
    },
    'POST'
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return state.user.emailToVerify;
});

export const updateUserThunk = createAsyncThunk<
  { sucess: iError, updateNamePayload: iUpadteNamePayload },
  iUpadteNamePayload,
  iThunkApi
>('user/updateUser', async (updateNamePayload: iUpadteNamePayload, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.UPDATE_USER_V2,
    { ...updateNamePayload, lang: state.content.userLang },
    'POST',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  thunkAPI.dispatch(getUserDetailsThunk())
  return { sucess: resp.data, updateNamePayload };
});

export const changUserLocaleOnServerThunk = createAsyncThunk<
  any,
  { locale: string },
  iThunkApi
>("consultant/changUserLocaleOnServer", async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.CHANGE_LOCALE_ON_SERVER,
    { locale: payload.locale },
    "POST",
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return { userDetails: resp.data.profile_data };
  }
  thunkAPI.dispatch(getUserDetailsThunk())
  return thunkAPI.rejectWithValue(resp.data);
});

export const updatePasswordThunk = createAsyncThunk<
  { sucess: iError },
  iUpadatePasswordPayload,
  iThunkApi
>('user/updatePassword', async (updatePassswordPayload: iUpadatePasswordPayload, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.UPDATE_USER_PASSWORD(updatePassswordPayload?.id),
    { ...updatePassswordPayload, lang: state.content.userLang },
    'PATCH',
    state.user.authToken.access_token
  );

  if (resp.data === 'Incorrect password') {
    return thunkAPI.rejectWithValue(resp.data);
  }

  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  thunkAPI.dispatch(getUserDetailsThunk())
  return { sucess: resp.data };
});

export const getNotificationsThunk = createAsyncThunk<
  { notifications: iNotification[]; meta: iPaginationMeta },
  { currentPage: number, pageLimit: number },
  iThunkApi
>('user/notifications', async ({ currentPage = 1, pageLimit = 10 }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_NOTIFICATIONS(
      currentPage,
      pageLimit,
    ),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return { notifications: resp.data.data, meta: resp.data.meta };
});

export const getUserReviewThunk = createAsyncThunk<
  { userReviews: iReviews[]; meta: iPaginationMeta },
  { currentPage: number, pageLimit: number, filter?: string | null, order?: string | null },
  iThunkApi
>(
  'consultant/getReviewsUser',
  async ({ currentPage, pageLimit, filter = null, order = null }, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.GET_REVIEWS_USER(
        currentPage,
        pageLimit,
        filter,
        order
      ),
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { userReviews: resp.data.data, meta: resp.data.meta };
  }
);

export const rechargeUserAccountThunk = createAsyncThunk<
  { sucess: iError },
  iPaymentPayload,
  iThunkApi
>('user/recharge', async (paymentPayload: iPaymentPayload, thunkAPI) => {
  const state = thunkAPI.getState();
  const payload = { ...paymentPayload, Description: "this is payment" }
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_LINK_PAYMENT,
    payload,
    'POST',
    state.user.authToken.access_token
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  window.location.href = resp?.data?.PaymentUrl;
  return { sucess: resp.data };
});

export const getPacakgesThunk = createAsyncThunk<
  { packages: iPackages[] },
  {},
  iThunkApi
>('user/pacakges', async (args, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.PACKAGES + '?lang=' + state.content.userLang,
    {},
    'GET',
    state.user.authToken.access_token
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { packages: resp.data.data };
});

export const deleteUserThunk = createAsyncThunk<
  { sucess: iError },
  { id: number },
  iThunkApi
>('user/deleteUser', async ({ id }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.DELETE_ACCOUNT_REQUEST(id),
    {},
    'DELETE',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  localStorage?.clear();
  window.location.href = "/login";
  return { sucess: resp.data };
});

export const getUserActivitiesThunk = createAsyncThunk<
  { activities: iActivities[] },
  {},
  iThunkApi
>(
  'user/getUserActivities',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.TRANCTIONAL_HISTORY + '?lang=' + state.content.userLang,
      {},
      'GET',
      state.user.authToken.access_token
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { activities: resp.data.data.data };
  }
);

export const getUserActivities = createAsyncThunk<
  { transactions: iTransactions[] },
  {},
  iThunkApi
>('user/getUserActivities',

  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const resp = await apiManager.request(
      API_ENDPOINTS.TRANCTIONAL_HISTORY_V2 + '?lang=' + state.content.userLang,
      {},
      'GET',
      state.user.authToken.access_token,
      REACT_APP_BASE_API_URL_V2
    );
    if (failStatusCodes.includes(resp.data.status)) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { transactions: resp.data };
  }
);


export const socailUserAccountThunk = createAsyncThunk<
  iAuthToken,
  iSocialLoginPayload,
  iThunkApi
>('user/socailLogin', async (payload: iSocialLoginPayload, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.SOCIAL_LOGIN,
    payload,
    'POST',
    state.user.authToken.access_token
  );

  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return resp.data;
});

export const verifyEmailThunk = createAsyncThunk<
  { sucess: iError },
  { email: string },
  iThunkApi
>('user/verifyEmail', async ({ email }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.VERIFY_EMAIL + '?user_email=' + email + '&lang=' + state.content.userLang,
    {},
    'GET',
    state.user.authToken.access_token
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { sucess: resp.data };
});


export const resetPasswordThunk = createAsyncThunk<
  { sucess: iError },
  { password: string, password_confirmation: string, token: string },
  iThunkApi
>('user/resetPassword', async ({ password, password_confirmation, token }, thunkAPI) => {
  const state = thunkAPI.getState();
  const payload = { password: password, password_confirmation: password_confirmation, token: token };
  const resp = await apiManager.request(
    API_ENDPOINTS.RESET_PASSWORD + '?lang=' + state.content.userLang,
    payload,
    'POST',
    // state.user.authToken.access_token
  );
  if (resp.data.status === false) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { sucess: resp.data };
});

export const getSchedules = createAsyncThunk<
  { result: iSchedule },
  {},
  iThunkApi
>('user/getSchedule', async ({ }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.GET_SCHEDULES(getTimeZoneOffset()),
    {},
    'GET',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { result: resp.data };
});

export const updateSchedules = createAsyncThunk<
  { result: { status: boolean, message: string } },
  { payload: iSchedule },
  iThunkApi
>('user/updateSchedule', async ({ payload }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.UPDATE_SCHEDULES,
    { ...payload, offset: getTimeZoneOffset() },
    'PUT',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }
  return { result: resp.data };
});

export const createSchedules = createAsyncThunk<
  { result: { status: boolean, message: string } },
  { payload: iSchedule },
  iThunkApi
>('user/createSchedule', async ({ payload }, thunkAPI) => {
  const state = thunkAPI.getState();
  const resp = await apiManager.request(
    API_ENDPOINTS.CREATE_SCHEDULES,
    { ...payload, offset: getTimeZoneOffset() },
    'POST',
    state.user.authToken.access_token,
    REACT_APP_BASE_API_URL_V2
  );
  if (failStatusCodes.includes(resp.data.status)) {
    return thunkAPI.rejectWithValue(resp.data);
  }

  return { result: resp.data };
});
