import React from 'react'

interface IProps {
  children: React.ReactElement
}

const LoaderNull = ({ children }: IProps) => {
  return (
    <React.Suspense fallback={<></>}>
      {children}
    </React.Suspense>
  )
}

export default LoaderNull