import React from 'react';
import { colors } from 'utils/Data';
import './Button.scss'

interface INavButton {
  children: React.ReactNode;
  onClick?: () => void;
  width?: string;
  className?: string;
}

const NavButton = (props: INavButton) => {

  return (
    <button
      className={`NavButton d-flex align-items-center ${props.className || ''}`}
      style={{ width: `${props.width}` }}
      color={colors.white}
      onClick={props.onClick || (() => { })}
    >
      {props.children}
    </button>
  );
};

export default NavButton;
